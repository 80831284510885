import { Component } from "@angular/core";
import Amplify from '@aws-amplify/core';
import {
  Platform,
  ActionSheetController,
  PopoverController,
} from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import {
  Router,
  NavigationEnd,
  NavigationStart,
} from "@angular/router";
import { AuthService } from "./auth/auth.service";
import { DynamicPopoverComponent } from "./components/dynamic-popover/dynamic-popover.component";
import { Storage } from '@ionic/storage';
import { CreateVendorInput, CreateNotificationInput, APIService } from './API.service';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { IBeacon } from '@ionic-native/ibeacon/ngx';

export interface PageConfig {
  title: string;
  showHeader: boolean;
  menu?: string;
  icon?: string;
  src?: string;
  showLogo?: boolean;
  showAdmin?: boolean;
  showHome?: boolean;
  isPublic?: boolean;
  back?: string;
  width?: number;
  autoLoad?: boolean;
}
export const appPages: { [key: string]: PageConfig } = {
  "/auth": {
    showHeader: false,
    title: "Sign In",
    isPublic: true,
    width: 12,
  },
  "/schedule": {
    title: "Scheduler",
    showHeader: true,
    showLogo: true,
    showHome: false,
    showAdmin: true,
  },
  "/diary": {
    title: "My Diary",
    showHeader: false,
    showLogo: true,
    showAdmin: false,
    showHome: false,
  },
  "/admin": {
    title: "Admin Portal",
    showHeader: true,
    showLogo: true,
    showHome: true,
  },
  "/settings": {
    title: "Preferences",
    showHeader: false,
    showLogo: true,
    showHome: true,
    autoLoad: true,
  },
  "/app-settings": {
    title: "App Setting",
    showHeader: false,
    showLogo: true,
    showHome: true,
    autoLoad: true,
  },
  "/alerts": {
    title: "Notifications & Alerts",
    showHeader: false,
    showLogo: true,
    showHome: true,
    autoLoad: true,
  },
  "/privacy": {
    title: "Data Privacy",
    showHeader: false,
    showLogo: true,
    showHome: true,
    isPublic: true,
    autoLoad: true,
  },
  "/about": {
    title: "About",
    showHeader: false,
    showLogo: true,
    showHome: true,
    isPublic: true,
    autoLoad: true,
  },
  "/welcome": {
    title: "Account Setup",
    showHeader: false,
    showLogo: true,
    showHome: true,
    isPublic: false,
    width: 12,
    autoLoad: true,
  },
  "/vendor": {
    title: "Vendor Sign In",
    showHeader: false,
    width: 12,
    autoLoad: true,
  }
};

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  get usePopover() {
    return this.platform.width() > 550;
  }

  pages: { [key: string]: PageConfig } = appPages;
  config: PageConfig = {
    showHeader: false,
    title: "Scheduler",
    isPublic: true,
  };

  isAdmin;
  loading = true;
  url: string;
  initialized = false;
  isDark;
  hideTips = true;

  isMobile;

  constructor(
    public platform: Platform,
    public auth: AuthService,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private action: ActionSheetController,
    private pop: PopoverController,
    private router: Router,
    private storage: Storage,
    private dl: Deeplinks,
    private title: Title,
    private ibeacon: IBeacon,
  ) {
    this.isMobile = this.platform.is('mobileweb');
    console.log("[ROUTES] Initialized");
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationStart) {
      }
      if (ev instanceof NavigationEnd) {
        this.url = ev.url.split("/").slice(0, 2).map(p => p.split("?")[0].split("#")[0]).join("/");
        const config = appPages[this.url] || {
          showHeader: false,
          showLogo: true,
          title: "Verisk",
          isPublic: true,
          width: 12,
        };
        this.isAdmin = this.auth.isAdmin;
        this.title.setTitle(config.title + " | MyCheckin");
        if (config.width != this.config.width) {
          this.loading = true;
          if (config.autoLoad) {
            setTimeout( () => {
              this.loading = false;
            }, 500);
          }
          this.config = config;
        } else {
          this.config = config;
          this.loading = false;
        }
      } 
    });

    if (this.platform.is('cordova')) {
      this.ibeacon.requestAlwaysAuthorization();
      let delegate = this.ibeacon.Delegate();
      delegate.didRangeBeaconsInRegion()
        .subscribe(
          data => console.log('didRangeBeaconsInRegion: ', data),
          error => console.error()
        );
      delegate.didStartMonitoringForRegion()
        .subscribe(
          data => console.log('didStartMonitoringForRegion: ', data),
          error => console.error()
        );
      delegate.didEnterRegion()
        .subscribe(
          data => {
            console.log('didEnterRegion: ', data);
          }
        );

      let beaconRegion = this.ibeacon.BeaconRegion('estimoteBeacons','B9407F30-F5F8-466E-AFF9-25556B57FE6D');
      this.ibeacon.startMonitoringForRegion(beaconRegion)
        .then(
          (val) => {
            console.log('Native layer received the request to monitoring');
            this.router.navigate(['/schedule', 'callback'], {
              queryParams: {
                eventName: 'beacon',
                eventMessage: val,
              }
            });
          },
          error => console.error('Native layer failed to begin monitoring: ', error)
        );
    }
    this.initalizeDeeplink();
    Amplify.configure({
      ...environment.aws,
      oauth: {
        ...environment.aws.oauth,
        redirectSignIn: environment.mobile
          ? environment.aws.oauth.dlRedirectSignIn
          : environment.local
          ? "http://localhost:8100/auth/callback/signin/"
          : environment.aws.oauth.redirectSignIn,
        redirectSignOut: environment.mobile
          ? environment.aws.oauth.dlRedirectSignOut
          : environment.local
          ? "http://localhost:8100/auth/callback/signout/"
          : environment.aws.oauth.redirectSignOut,
      },
    });

    this.auth.initialize().then( (loggedin) => {
      this.initializeApp(3000);
    });
  }

  initalizeDeeplink() {
    console.log("[DEEPLINK] Initialized")
    this.dl
      .route({
        "/auth/callback/signin/": {},
        "/auth/callback/signout/": {},
        "/auth/callback/signin": {},
        "/auth/callback/signout": {},
        "/schedule/callback/": {},
        "/schedule/callback": {},
      })
      .subscribe((match) => {
        if (match.$link.path == '/schedule/callback/' || match.$link.path == '/schedule/callback') {
          console.log("[DEEPLINK] Matched - callback", match);
          document.location.href = `http://localhost${match.$link.path}?eventName=${match.$args.eventName}&eventMessage=${match.$args.eventMessage}`;
        } else {
          if (environment.aws.oauth.responseType == 'token') {
            console.log("[DEEPLINK] Matched - token", match);
            document.location.href = `http://localhost${match.$link.path}#${match.$link.fragment}`;
          } else if (environment.aws.oauth.responseType == 'code') {
            console.log("[DEEPLINK] Matched - code", match);
            document.location.href = `http://localhost${match.$link.path}?code=${match.$args.code}&state=${match.$args.state}`;
          }
        }
      }, (nomatch) => {
        console.warn("[DEEPLINK] no match", nomatch);
      });
  }

  initializeApp(timeout=1000) {
    console.log("[APP] Initializing...");
    this.storage.get('dark').then( isDark => {
      this.isDark = isDark;
      document.body.classList.toggle('dark', isDark);
    });
    this.storage.get('tips').then( hideTips => {
      this.hideTips = hideTips;
      document.body.classList.toggle('hide-tips', hideTips);
    });
    return this.platform.ready().then(() => {
      this.initalizeDeeplink();
      this.statusBar.show();
      this.initialized = true;
      this.splashScreen.hide();
      console.log("[APP] Initialized");
      setTimeout( () => {
        if (this.loading) {
          this.loading = false;
          console.log("[APP] Loading timeout")
        }
      }, timeout );
    });
  }

  // Profile
  async showProfile(ev) {
    if (this.usePopover) {
      if (!(await this.pop.getTop())) {
        (
          await this.pop.create({
            component: DynamicPopoverComponent,
            componentProps: {
              mode: "popover",
              props: [
                {
                  text: "RTO Preferences",
                  icon: "options-outline",
                  handler: () => {
                    this.router.navigate(["/settings"]);
                  },
                },
                {
                  text: "App Settings",
                  icon: "settings",
                  handler: () => {
                    this.router.navigate(["/app-settings"]);
                  },
                },
                {
                  text: "Sign Out",
                  icon: "log-out-outline",
                  role: "destructive",
                  handler: () => {
                    this.auth.signOut();
                  },
                },
              ],
            },
            event: ev,
            showBackdrop: false,
          })
        ).present();
      }
    } else {
      if (!(await this.action.getTop())) {
        const actions = this.auth.isAdmin
          ? [
              {
                text: "Admin Portal",
                icon: "desktop-outline",
                role: null,
                handler: () => {
                  this.router.navigate(["/admin/offices"]);
                },
              },
            ]
          : [];
        (
          await this.action.create({
            header: "Profile",
            buttons: actions.concat([
              {
                text: "RTO Preferences",
                icon: "options-outline",
                role: null,
                handler: () => {
                  this.router.navigate(["/settings"]);
                },
              },
              {
                text: "App Settings",
                icon: "settings",
                role: null,
                handler: () => {
                  this.router.navigate(["/app-settings"]);
                },
              },
              {
                text: "Sign Out",
                icon: "log-out-outline",
                role: "destructive",
                handler: () => {
                  this.auth.signOut();
                },
              },
              {
                text: "Cancel",
                icon: "close",
                role: "cancel",
                handler: null,
              },
            ]),
          })
        ).present();
      }
    }
  }

  async showPages(ev) {
    if (this.usePopover) {
      if (!(await this.pop.getTop())) {
        (
          await this.pop.create({
            component: DynamicPopoverComponent,
            componentProps: {
              mode: "popover",
              props: [
                {
                  text: "About",
                  icon: "help-circle-outline",
                  handler: () => {
                    this.router.navigate(["/about"]);
                  },
                },
                {
                  text: "Data Privacy",
                  icon: "document-outline",
                  handler: () => {
                    this.router.navigate(["/privacy"]);
                  },
                },
              ],
            },
            event: ev,
            showBackdrop: false,
          })
        ).present();
      }
    } else {
      if (!(await this.action.getTop())) {
        (
          await this.action.create({
            header: "Verisk Scheduler",
            buttons: [
              {
                text: "About",
                icon: "help-circle-outline",
                handler: () => {
                  this.router.navigate(["/about"]);
                },
              },
              {
                text: "Data Privacy",
                icon: "document-outline",
                handler: () => {
                  this.router.navigate(["/privacy"]);
                },
              },
            ],
          })
        ).present();
      }
    }
  }

  // Settings
  async showSettings() {
    this.router.navigate(["/settings"]);
  }
}
