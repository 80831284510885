import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
})
export class EmptyStateComponent implements OnInit {
  @Input() message: string = "Oops, looks like there's nothing here right now.";
  @Input() cta: string;
  @Input() ctaIcon?: string;
  @Input() ctaSrc?: string;
  @Input() img: string = 'assets/img/empty.png';
  @Output() ctaClick: EventEmitter<boolean> = new EventEmitter();
  constructor() { }

  ngOnInit() {}

  handleCtaClick() {
    this.ctaClick.emit(true);
  }

}
