import { Component, OnInit, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { APIService, GetOfficeQuery, CreateOfficeInput } from 'src/app/API.service';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss'],
})
export class SearchModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() mode: 'offices';
  @Input() back = true;
  searchValue: string;
  items: CreateOfficeInput[];
  loading = true;
  backbuttonSubscription: Subscription;
  rawQuery: any[];
  isSearching = false;
  constructor(
    private modal: ModalController,
    private api: APIService,
    private plt: Platform
  ) {
  }

  ngOnInit() {
    this.loading = true;
    if (this.mode == 'offices') {
      this.api.ListOffices(undefined,200,undefined).then( (offices) => {
        this.items = this.rawQuery = offices.items.sort( (a, b) => b.count - a.count );
        this.loading = false;
      })
    }
    this.backbuttonSubscription = this.plt.backButton.subscribe( (ev) => {
      this.dismiss();
    });
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.backbuttonSubscription.unsubscribe();
  }

  search(val:string) {
    if (!this.isSearching) {
      setTimeout( () => {
        if (this.isSearching) {
          this.isSearching = false;
        }
      }, 5000);
      this.items = this.rawQuery.filter( v => JSON.stringify(v).toLowerCase().includes(val.toLowerCase()) );
      this.isSearching = false;
    }
  }

  dismiss(data?) {
    this.modal.dismiss(data);
  }

}
