import { Injectable } from "@angular/core";
import {
  CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,
} from "@angular/router";
import { AuthService } from './auth.service';

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService,
  ) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
      return this.auth.initialize().then( init => {
        if (init) {
          return true;
        } else {
          return this.router.navigate(['/auth']).then( () => false );
        }
      });
  }
}
