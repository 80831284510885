import { Component, OnInit, Input, Output } from '@angular/core';
import { ActionSheetController, PopoverController, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-dynamic-popover',
  templateUrl: './dynamic-popover.component.html',
  styleUrls: ['./dynamic-popover.component.scss'],
})
export class DynamicPopoverComponent implements OnInit {
  @Input() props: any[];
  @Input() mode: 'popover' | 'action' | 'modal' = 'action';
  @Input() header;

  constructor(
    private pop: PopoverController,
    private action: ActionSheetController
  ) { }

  ngOnInit() {
    if (this.mode == 'action') {
      this.tryAction();
    }
  }

  dismiss() {
    this.pop.dismiss();
  }

  async tryAction() {
    if (!await this.action.getTop()) {
      (await this.action.create({
        header: this.header,
        buttons: this.props,
      })).present();
    }
    
  }

}
