import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QRScanner, QRScannerStatus } from '@ionic-native/qr-scanner/ngx';

@Component({
  selector: 'app-qrscanner',
  templateUrl: './qrscanner.component.html',
  styleUrls: ['./qrscanner.component.scss'],
})
export class QRScannerComponent implements OnInit {
  @Input() type = "fab-button";
  @Input() horizontal = "end";
  @Input() vertical = "bottom";
  @Output() scan: EventEmitter<any> = new EventEmitter();
  constructor(
    private qr: QRScanner,
  ) { }

  ngOnInit() {}

  async scanQr() {
    const status:QRScannerStatus = await this.qr.prepare();
    if (status.authorized) {
      // start scanning
      const qrStatus = await this.qr.show();
      if (qrStatus.showing) {
        document.querySelector('ion-app').classList.add('transparent-body');
        setTimeout( () => {
          document.querySelector('ion-app').classList.remove('transparent-body');
        }, 5000);
      }
      let scanSub = this.qr.scan().subscribe((res:string) => {
        this.scan.emit(res);
        this.qr.hide();
        scanSub.unsubscribe();
        document.querySelector('ion-app').classList.remove('transparent-body');
      });
    } else if (status.denied) {
      // camera permission was permanently denied
      // you must use QRScanner.openSettings() method to guide the user to the settings page
      // then they can grant the permission from there
    } else {
      // permission was denied, but not permanently. You can ask for permission again at a later time.
    }
  }
}
