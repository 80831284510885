import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent implements OnInit {

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private nav: NavController,
    private router: Router,
    private title: Title
  ) {
  }

  ngOnInit() {
    this.title.setTitle('Redirecting MyCheckin authentication...')
    this.route.params.subscribe( async (p) => {
      console.log(p, this.route.snapshot.queryParams, this.route.snapshot.fragment);
      switch(p.event) {
        case 'signin':
          if (this.route.snapshot.queryParams?.error == 'access_denied') {
            return this.router.navigate(['/auth'], {
              queryParams: {
                fromOkta: true
              }
            });
          }
          if (this.auth.user) {
            return this.nav.navigateRoot('/schedule');
          } else {
            return this.auth.initialize().then( (init) => {
              if (init) {
                return this.nav.navigateRoot('/schedule');
              }
              return this.nav.navigateRoot('/auth');
            });
          }
        case 'signout':
          this.auth.user = null;
          return this.nav.navigateRoot('/auth');
      }
    });
  }
}
