import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { IonSegment } from '@ionic/angular';

@Component({
  selector: 'app-week-tab',
  templateUrl: './week-tab.component.html',
  styleUrls: ['./week-tab.component.scss'],
})
export class WeekTabComponent implements OnInit {
  @ViewChild(IonSegment, {static: true}) segment: IonSegment;
  @Input() start;
  @Input() weeks: number = 1;
  @Input() date: Date;
  @Output() dateChange:EventEmitter<Boolean[]> = new EventEmitter();

  @Input() days: Date[] = [];
  @Input() selected:any[] = [];
  constructor() { }

  ngOnInit() {
  }

  toggle(i) {
    if (this.selected[i]) {
      this.selected[i] = null;
    } else {
      if (i > 4) {
        this.selected[i] = 'custom';
      } else {
        this.selected[i] = this.days[i].toDateString();
      }      
    }
    this.dateChange.emit(this.selected)
  }
}
