import { Injectable } from "@angular/core";
import { Auth } from "@aws-amplify/auth";
import { APIService, GetUserQuery, CreateNotificationInput, CreateVendorInput } from "../API.service";
import { NavController } from '@ionic/angular';


@Injectable()
export class AuthService {
  public reload = true;
  public user: GetUserQuery;
  public vendors: CreateVendorInput[];
  public notifications: {
    badge: number;
    items: CreateNotificationInput[];
    token: string;
  } = {
    badge: null,
    items: [],
    token: null,
  };
  private metaSub;
  
  public get authenticatedUser() {
    return Auth.currentAuthenticatedUser();
  }
  public get userInfo() {
    return Auth.currentUserInfo();
  }
  public get userGroups() {
    return Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          return user.signInUserSession.accessToken.payload["cognito:groups"];
        }
        return;
      })
  }

  public get isAdmin() {
    return this.userGroups
      .then((grp) => {
        try {
          return grp.includes("admins");
        } catch {
          return false;
        }
      });
  }

  constructor(
    private api: APIService,
    private nav: NavController,
    ) {
  }

  public async registerUser(data) {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch {
      user = null;
    }
    if (user) {
      if (!this.user) {
        this.user = await this.api
          .CreateUser({
            id: user.username,
            name: user.signInUserSession.idToken.payload.name,
            email: user.signInUserSession.idToken.payload.email,
            ...data
          });
        this.initialize(user);
      }
    }
  }

  public async initialize(user?) {
    if (this.user) {
      return true;
    } else {
      if (!user) {
        try {
          user = await Auth.currentAuthenticatedUser();
        } catch {
          user = null;
        }
      }
      if (user) {
        this.user = await this.api.GetUser(user.username);
        if (!this.user) {
          this.nav.navigateRoot('/welcome');
          return true;
        }
        this.user = await this.api
          .UpdateUser({
            id: user.username,
            name: user.signInUserSession.idToken.payload.name,
            email: user.signInUserSession.idToken.payload.email,
          });
        this.listNotifications();
        this.listVendors();
        this.metaSub = setInterval( () => {
          this.listNotifications();
          this.listVendors();
        }, 30000);
        return true;
      }
      this.clearUser();
      return false;
    }
  }

  async listNotifications() {
    const notifs = await this.api.ListNotifications({
      or: [
        {officeIds: { contains: this.user.officeId }},
        {userIds: { contains: this.user.id }},
      ]
    });
    const read = this.user.notifications || [];
    this.notifications.items = notifs.items;
    this.notifications.token = notifs.nextToken;
    this.notifications.badge = notifs.items.filter( n => !read.includes(n.id) ).length;
  }

  async listVendors() {
    this.vendors = (await this.api.ListVendorsByOffice(this.user.officeId, null, { visible: { eq: true } })).items;
  }

  oktaSignIn() {
    return Auth.federatedSignIn({
      customProvider: 'okta',
    }).then( (creds) => {
      this.initialize();
      if (creds) {
        return Auth.currentAuthenticatedUser();
      }
      return;
    }).then( (user) => {
      this.initialize(user);
    })
  }

  vendorLogin(password: string) {
    return Auth.signIn('mycheckin@verisk.com', password).then( (user) => user );
  }

  vendorConfirm(user, newPassword) {
    return Auth.completeNewPassword(user, newPassword, {
      email: 'mycheckin@verisk.com',
    })
  }

  clearUser() {
    this.user = this.notifications = this.vendors = null;
    if (this.metaSub) {
      clearInterval(this.metaSub);
    }
  }

  /** signout */
  public signOut() {
    return Auth.signOut().then( (signedout) => {
      this.clearUser();
      this.nav.navigateRoot('/auth');
    })
  }
}
