/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type __SubscriptionContainer = {
  onCreateOffice: OnCreateOfficeSubscription;
  onUpdateOffice: OnUpdateOfficeSubscription;
  onDeleteOffice: OnDeleteOfficeSubscription;
  onCreateSlot: OnCreateSlotSubscription;
  onUpdateSlot: OnUpdateSlotSubscription;
  onDeleteSlot: OnDeleteSlotSubscription;
  onCreateNotification: OnCreateNotificationSubscription;
  onUpdateNotification: OnUpdateNotificationSubscription;
  onDeleteNotification: OnDeleteNotificationSubscription;
  onCreateUser: OnCreateUserSubscription;
  onUpdateUser: OnUpdateUserSubscription;
  onDeleteUser: OnDeleteUserSubscription;
  onCreateSchedule: OnCreateScheduleSubscription;
  onUpdateSchedule: OnUpdateScheduleSubscription;
  onDeleteSchedule: OnDeleteScheduleSubscription;
  onCreateEvent: OnCreateEventSubscription;
  onUpdateEvent: OnUpdateEventSubscription;
  onDeleteEvent: OnDeleteEventSubscription;
  onCreateVendor: OnCreateVendorSubscription;
  onUpdateVendor: OnUpdateVendorSubscription;
  onDeleteVendor: OnDeleteVendorSubscription;
  onCreateBeacon: OnCreateBeaconSubscription;
  onUpdateBeacon: OnUpdateBeaconSubscription;
  onDeleteBeacon: OnDeleteBeaconSubscription;
  onCreatePublishTest: OnCreatePublishTestSubscription;
  onUpdatePublishTest: OnUpdatePublishTestSubscription;
  onDeletePublishTest: OnDeletePublishTestSubscription;
};

export type CreateOfficeInput = {
  id?: string | null;
  country?: string | null;
  state?: string | null;
  city?: string | null;
  address?: string | null;
  parent?: string | null;
  status?: string | null;
  count?: number | null;
  quarantine?: string | null;
  reopening?: string | null;
  ihme_safe?: string | null;
  searchString?: string | null;
  arrival?: SlotConfigInput | null;
  departure?: SlotConfigInput | null;
  break?: SlotConfigInput | null;
  return?: SlotConfigInput | null;
};

export type SlotConfigInput = {
  enabled: boolean;
  capacity: number;
  start: string;
  end: string;
  type: string;
  blocks?: number | null;
  interval?: number | null;
};

export type ModelOfficeConditionInput = {
  country?: ModelStringInput | null;
  state?: ModelStringInput | null;
  city?: ModelStringInput | null;
  address?: ModelStringInput | null;
  parent?: ModelStringInput | null;
  status?: ModelStringInput | null;
  count?: ModelIntInput | null;
  quarantine?: ModelStringInput | null;
  reopening?: ModelStringInput | null;
  ihme_safe?: ModelStringInput | null;
  searchString?: ModelStringInput | null;
  and?: Array<ModelOfficeConditionInput | null> | null;
  or?: Array<ModelOfficeConditionInput | null> | null;
  not?: ModelOfficeConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type Office = {
  __typename: "Office";
  id: string;
  country?: string | null;
  state?: string | null;
  city?: string | null;
  address?: string | null;
  parent?: string | null;
  status?: string | null;
  count?: number | null;
  quarantine?: string | null;
  reopening?: string | null;
  ihme_safe?: string | null;
  searchString?: string | null;
  arrival?: SlotConfig | null;
  departure?: SlotConfig | null;
  break?: SlotConfig | null;
  return?: SlotConfig | null;
  slots?: ModelSlotConnection | null;
  users?: ModelUserConnection | null;
  createdAt: string;
  updatedAt: string;
};

export type SlotConfig = {
  __typename: "SlotConfig";
  enabled: boolean;
  capacity: number;
  start: string;
  end: string;
  type: string;
  blocks?: number | null;
  interval?: number | null;
};

export type ModelSlotConnection = {
  __typename: "ModelSlotConnection";
  items: Array<Slot | null>;
  nextToken?: string | null;
};

export type Slot = {
  __typename: "Slot";
  id: string;
  start?: string | null;
  date?: string | null;
  type?: string | null;
  interval?: number | null;
  capacity?: number | null;
  filled?: number | null;
  officeId: string;
  office?: Office | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection";
  items: Array<User | null>;
  nextToken?: string | null;
};

export type User = {
  __typename: "User";
  id: string;
  email?: string | null;
  commute?: string | null;
  name?: string | null;
  prefBlock?: Array<string | null> | null;
  prefDays?: Array<string | null> | null;
  prefStart?: Array<string | null> | null;
  quarantine?: boolean | null;
  wfh?: boolean | null;
  officeId: string;
  office?: Office | null;
  schedules?: ModelScheduleConnection | null;
  notifications?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelScheduleConnection = {
  __typename: "ModelScheduleConnection";
  items: Array<Schedule | null>;
  nextToken?: string | null;
};

export type Schedule = {
  __typename: "Schedule";
  id: string;
  date?: string | null;
  arrivalId: string;
  arrival?: Slot | null;
  departureId: string;
  departure?: Slot | null;
  breakId: string;
  break?: Slot | null;
  returnId: string;
  return?: Slot | null;
  userId: string;
  user?: User | null;
  events?: ModelEventConnection | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelEventConnection = {
  __typename: "ModelEventConnection";
  items: Array<Event | null>;
  nextToken?: string | null;
};

export type Event = {
  __typename: "Event";
  id: string;
  type: string;
  note?: string | null;
  time?: number | null;
  entity?: string | null;
  owner?: string | null;
  scheduleId: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateOfficeInput = {
  id: string;
  country?: string | null;
  state?: string | null;
  city?: string | null;
  address?: string | null;
  parent?: string | null;
  status?: string | null;
  count?: number | null;
  quarantine?: string | null;
  reopening?: string | null;
  ihme_safe?: string | null;
  searchString?: string | null;
  arrival?: SlotConfigInput | null;
  departure?: SlotConfigInput | null;
  break?: SlotConfigInput | null;
  return?: SlotConfigInput | null;
};

export type DeleteOfficeInput = {
  id: string;
};

export type CreateSlotInput = {
  id?: string | null;
  start?: string | null;
  date?: string | null;
  type?: string | null;
  interval?: number | null;
  capacity?: number | null;
  filled?: number | null;
  officeId: string;
};

export type ModelSlotConditionInput = {
  start?: ModelStringInput | null;
  date?: ModelStringInput | null;
  type?: ModelStringInput | null;
  interval?: ModelIntInput | null;
  capacity?: ModelIntInput | null;
  filled?: ModelIntInput | null;
  officeId?: ModelIDInput | null;
  and?: Array<ModelSlotConditionInput | null> | null;
  or?: Array<ModelSlotConditionInput | null> | null;
  not?: ModelSlotConditionInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type UpdateSlotInput = {
  id: string;
  start?: string | null;
  date?: string | null;
  type?: string | null;
  interval?: number | null;
  capacity?: number | null;
  filled?: number | null;
  officeId?: string | null;
};

export type DeleteSlotInput = {
  id: string;
};

export type CreateNotificationInput = {
  id?: string | null;
  subject: string;
  message: string;
  from: string;
  replyTo?: string | null;
  officeIds?: Array<string | null> | null;
  time?: number | null;
  slotIds?: Array<string | null> | null;
  userIds?: Array<string | null> | null;
};

export type ModelNotificationConditionInput = {
  subject?: ModelStringInput | null;
  message?: ModelStringInput | null;
  from?: ModelStringInput | null;
  replyTo?: ModelStringInput | null;
  officeIds?: ModelStringInput | null;
  time?: ModelIntInput | null;
  slotIds?: ModelStringInput | null;
  userIds?: ModelStringInput | null;
  and?: Array<ModelNotificationConditionInput | null> | null;
  or?: Array<ModelNotificationConditionInput | null> | null;
  not?: ModelNotificationConditionInput | null;
};

export type Notification = {
  __typename: "Notification";
  id: string;
  subject: string;
  message: string;
  from: string;
  replyTo?: string | null;
  officeIds?: Array<string | null> | null;
  time?: number | null;
  slotIds?: Array<string | null> | null;
  userIds?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateNotificationInput = {
  id: string;
  subject?: string | null;
  message?: string | null;
  from?: string | null;
  replyTo?: string | null;
  officeIds?: Array<string | null> | null;
  time?: number | null;
  slotIds?: Array<string | null> | null;
  userIds?: Array<string | null> | null;
};

export type DeleteNotificationInput = {
  id: string;
};

export type CreateUserInput = {
  id?: string | null;
  email?: string | null;
  commute?: string | null;
  name?: string | null;
  prefBlock?: Array<string | null> | null;
  prefDays?: Array<string | null> | null;
  prefStart?: Array<string | null> | null;
  quarantine?: boolean | null;
  wfh?: boolean | null;
  officeId: string;
  notifications?: Array<string | null> | null;
};

export type ModelUserConditionInput = {
  email?: ModelStringInput | null;
  commute?: ModelStringInput | null;
  name?: ModelStringInput | null;
  prefBlock?: ModelStringInput | null;
  prefDays?: ModelStringInput | null;
  prefStart?: ModelStringInput | null;
  quarantine?: ModelBooleanInput | null;
  wfh?: ModelBooleanInput | null;
  officeId?: ModelIDInput | null;
  notifications?: ModelStringInput | null;
  and?: Array<ModelUserConditionInput | null> | null;
  or?: Array<ModelUserConditionInput | null> | null;
  not?: ModelUserConditionInput | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateUserInput = {
  id: string;
  email?: string | null;
  commute?: string | null;
  name?: string | null;
  prefBlock?: Array<string | null> | null;
  prefDays?: Array<string | null> | null;
  prefStart?: Array<string | null> | null;
  quarantine?: boolean | null;
  wfh?: boolean | null;
  officeId?: string | null;
  notifications?: Array<string | null> | null;
};

export type DeleteUserInput = {
  id: string;
};

export type CreateScheduleInput = {
  id?: string | null;
  date?: string | null;
  arrivalId: string;
  departureId: string;
  breakId: string;
  returnId: string;
  userId: string;
};

export type ModelScheduleConditionInput = {
  date?: ModelStringInput | null;
  arrivalId?: ModelIDInput | null;
  departureId?: ModelIDInput | null;
  breakId?: ModelIDInput | null;
  returnId?: ModelIDInput | null;
  and?: Array<ModelScheduleConditionInput | null> | null;
  or?: Array<ModelScheduleConditionInput | null> | null;
  not?: ModelScheduleConditionInput | null;
};

export type UpdateScheduleInput = {
  id: string;
  date?: string | null;
  arrivalId?: string | null;
  departureId?: string | null;
  breakId?: string | null;
  returnId?: string | null;
  userId?: string | null;
};

export type DeleteScheduleInput = {
  id: string;
};

export type CreateEventInput = {
  id?: string | null;
  type: string;
  note?: string | null;
  time?: number | null;
  entity?: string | null;
  owner?: string | null;
  scheduleId: string;
};

export type ModelEventConditionInput = {
  type?: ModelStringInput | null;
  note?: ModelStringInput | null;
  time?: ModelIntInput | null;
  entity?: ModelStringInput | null;
  scheduleId?: ModelIDInput | null;
  and?: Array<ModelEventConditionInput | null> | null;
  or?: Array<ModelEventConditionInput | null> | null;
  not?: ModelEventConditionInput | null;
};

export type UpdateEventInput = {
  id: string;
  type?: string | null;
  note?: string | null;
  time?: number | null;
  entity?: string | null;
  owner?: string | null;
  scheduleId?: string | null;
};

export type DeleteEventInput = {
  id: string;
};

export type CreateVendorInput = {
  id?: string | null;
  type?: string | null;
  status?: string | null;
  options?: Array<string | null> | null;
  name?: string | null;
  visible?: boolean | null;
  icon?: string | null;
  src?: string | null;
  officeId?: string | null;
};

export type ModelVendorConditionInput = {
  type?: ModelStringInput | null;
  status?: ModelStringInput | null;
  options?: ModelStringInput | null;
  name?: ModelStringInput | null;
  visible?: ModelBooleanInput | null;
  icon?: ModelStringInput | null;
  src?: ModelStringInput | null;
  officeId?: ModelStringInput | null;
  and?: Array<ModelVendorConditionInput | null> | null;
  or?: Array<ModelVendorConditionInput | null> | null;
  not?: ModelVendorConditionInput | null;
};

export type Vendor = {
  __typename: "Vendor";
  id: string;
  type?: string | null;
  status?: string | null;
  options?: Array<string | null> | null;
  name?: string | null;
  visible?: boolean | null;
  icon?: string | null;
  src?: string | null;
  officeId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateVendorInput = {
  id: string;
  type?: string | null;
  status?: string | null;
  options?: Array<string | null> | null;
  name?: string | null;
  visible?: boolean | null;
  icon?: string | null;
  src?: string | null;
  officeId?: string | null;
};

export type DeleteVendorInput = {
  id: string;
};

export type CreateBeaconInput = {
  id?: string | null;
  uuid: string;
  name?: string | null;
  message?: string | null;
  type?: string | null;
  callback?: string | null;
  officeId?: string | null;
};

export type ModelBeaconConditionInput = {
  uuid?: ModelStringInput | null;
  name?: ModelStringInput | null;
  message?: ModelStringInput | null;
  type?: ModelStringInput | null;
  callback?: ModelStringInput | null;
  officeId?: ModelStringInput | null;
  and?: Array<ModelBeaconConditionInput | null> | null;
  or?: Array<ModelBeaconConditionInput | null> | null;
  not?: ModelBeaconConditionInput | null;
};

export type Beacon = {
  __typename: "Beacon";
  id: string;
  uuid: string;
  name?: string | null;
  message?: string | null;
  type?: string | null;
  callback?: string | null;
  officeId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateBeaconInput = {
  id: string;
  uuid?: string | null;
  name?: string | null;
  message?: string | null;
  type?: string | null;
  callback?: string | null;
  officeId?: string | null;
};

export type DeleteBeaconInput = {
  id: string;
};

export type CreatePublishTestInput = {
  id?: string | null;
  name: string;
  description?: string | null;
};

export type ModelPublishTestConditionInput = {
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  and?: Array<ModelPublishTestConditionInput | null> | null;
  or?: Array<ModelPublishTestConditionInput | null> | null;
  not?: ModelPublishTestConditionInput | null;
};

export type PublishTest = {
  __typename: "PublishTest";
  id: string;
  name: string;
  description?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdatePublishTestInput = {
  id: string;
  name?: string | null;
  description?: string | null;
};

export type DeletePublishTestInput = {
  id: string;
};

export type ModelOfficeFilterInput = {
  id?: ModelIDInput | null;
  country?: ModelStringInput | null;
  state?: ModelStringInput | null;
  city?: ModelStringInput | null;
  address?: ModelStringInput | null;
  parent?: ModelStringInput | null;
  status?: ModelStringInput | null;
  count?: ModelIntInput | null;
  quarantine?: ModelStringInput | null;
  reopening?: ModelStringInput | null;
  ihme_safe?: ModelStringInput | null;
  searchString?: ModelStringInput | null;
  and?: Array<ModelOfficeFilterInput | null> | null;
  or?: Array<ModelOfficeFilterInput | null> | null;
  not?: ModelOfficeFilterInput | null;
};

export type ModelOfficeConnection = {
  __typename: "ModelOfficeConnection";
  items: Array<Office | null>;
  nextToken?: string | null;
};

export type ModelSlotFilterInput = {
  id?: ModelIDInput | null;
  start?: ModelStringInput | null;
  date?: ModelStringInput | null;
  type?: ModelStringInput | null;
  interval?: ModelIntInput | null;
  capacity?: ModelIntInput | null;
  filled?: ModelIntInput | null;
  officeId?: ModelIDInput | null;
  and?: Array<ModelSlotFilterInput | null> | null;
  or?: Array<ModelSlotFilterInput | null> | null;
  not?: ModelSlotFilterInput | null;
};

export type ModelNotificationFilterInput = {
  id?: ModelIDInput | null;
  subject?: ModelStringInput | null;
  message?: ModelStringInput | null;
  from?: ModelStringInput | null;
  replyTo?: ModelStringInput | null;
  officeIds?: ModelStringInput | null;
  time?: ModelIntInput | null;
  slotIds?: ModelStringInput | null;
  userIds?: ModelStringInput | null;
  and?: Array<ModelNotificationFilterInput | null> | null;
  or?: Array<ModelNotificationFilterInput | null> | null;
  not?: ModelNotificationFilterInput | null;
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection";
  items: Array<Notification | null>;
  nextToken?: string | null;
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null;
  email?: ModelStringInput | null;
  commute?: ModelStringInput | null;
  name?: ModelStringInput | null;
  prefBlock?: ModelStringInput | null;
  prefDays?: ModelStringInput | null;
  prefStart?: ModelStringInput | null;
  quarantine?: ModelBooleanInput | null;
  wfh?: ModelBooleanInput | null;
  officeId?: ModelIDInput | null;
  notifications?: ModelStringInput | null;
  and?: Array<ModelUserFilterInput | null> | null;
  or?: Array<ModelUserFilterInput | null> | null;
  not?: ModelUserFilterInput | null;
};

export type ModelScheduleFilterInput = {
  id?: ModelIDInput | null;
  date?: ModelStringInput | null;
  arrivalId?: ModelIDInput | null;
  departureId?: ModelIDInput | null;
  breakId?: ModelIDInput | null;
  returnId?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  and?: Array<ModelScheduleFilterInput | null> | null;
  or?: Array<ModelScheduleFilterInput | null> | null;
  not?: ModelScheduleFilterInput | null;
};

export type ModelEventFilterInput = {
  id?: ModelIDInput | null;
  type?: ModelStringInput | null;
  note?: ModelStringInput | null;
  time?: ModelIntInput | null;
  entity?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  scheduleId?: ModelIDInput | null;
  and?: Array<ModelEventFilterInput | null> | null;
  or?: Array<ModelEventFilterInput | null> | null;
  not?: ModelEventFilterInput | null;
};

export type ModelVendorFilterInput = {
  id?: ModelIDInput | null;
  type?: ModelStringInput | null;
  status?: ModelStringInput | null;
  options?: ModelStringInput | null;
  name?: ModelStringInput | null;
  visible?: ModelBooleanInput | null;
  icon?: ModelStringInput | null;
  src?: ModelStringInput | null;
  officeId?: ModelStringInput | null;
  and?: Array<ModelVendorFilterInput | null> | null;
  or?: Array<ModelVendorFilterInput | null> | null;
  not?: ModelVendorFilterInput | null;
};

export type ModelVendorConnection = {
  __typename: "ModelVendorConnection";
  items: Array<Vendor | null>;
  nextToken?: string | null;
};

export type ModelBeaconFilterInput = {
  id?: ModelIDInput | null;
  uuid?: ModelStringInput | null;
  name?: ModelStringInput | null;
  message?: ModelStringInput | null;
  type?: ModelStringInput | null;
  callback?: ModelStringInput | null;
  officeId?: ModelStringInput | null;
  and?: Array<ModelBeaconFilterInput | null> | null;
  or?: Array<ModelBeaconFilterInput | null> | null;
  not?: ModelBeaconFilterInput | null;
};

export type ModelBeaconConnection = {
  __typename: "ModelBeaconConnection";
  items: Array<Beacon | null>;
  nextToken?: string | null;
};

export type ModelPublishTestFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  and?: Array<ModelPublishTestFilterInput | null> | null;
  or?: Array<ModelPublishTestFilterInput | null> | null;
  not?: ModelPublishTestFilterInput | null;
};

export type ModelPublishTestConnection = {
  __typename: "ModelPublishTestConnection";
  items: Array<PublishTest | null>;
  nextToken?: string | null;
};

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type SearchableUserFilterInput = {
  id?: SearchableIDFilterInput | null;
  email?: SearchableStringFilterInput | null;
  commute?: SearchableStringFilterInput | null;
  name?: SearchableStringFilterInput | null;
  prefBlock?: SearchableStringFilterInput | null;
  prefDays?: SearchableStringFilterInput | null;
  prefStart?: SearchableStringFilterInput | null;
  quarantine?: SearchableBooleanFilterInput | null;
  wfh?: SearchableBooleanFilterInput | null;
  officeId?: SearchableIDFilterInput | null;
  notifications?: SearchableStringFilterInput | null;
  and?: Array<SearchableUserFilterInput | null> | null;
  or?: Array<SearchableUserFilterInput | null> | null;
  not?: SearchableUserFilterInput | null;
};

export type SearchableIDFilterInput = {
  ne?: string | null;
  gt?: string | null;
  lt?: string | null;
  gte?: string | null;
  lte?: string | null;
  eq?: string | null;
  match?: string | null;
  matchPhrase?: string | null;
  matchPhrasePrefix?: string | null;
  multiMatch?: string | null;
  exists?: boolean | null;
  wildcard?: string | null;
  regexp?: string | null;
  range?: Array<string | null> | null;
};

export type SearchableStringFilterInput = {
  ne?: string | null;
  gt?: string | null;
  lt?: string | null;
  gte?: string | null;
  lte?: string | null;
  eq?: string | null;
  match?: string | null;
  matchPhrase?: string | null;
  matchPhrasePrefix?: string | null;
  multiMatch?: string | null;
  exists?: boolean | null;
  wildcard?: string | null;
  regexp?: string | null;
  range?: Array<string | null> | null;
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null;
  ne?: boolean | null;
};

export type SearchableUserSortInput = {
  field?: SearchableUserSortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableUserSortableFields {
  id = "id",
  email = "email",
  commute = "commute",
  name = "name",
  prefBlock = "prefBlock",
  prefDays = "prefDays",
  prefStart = "prefStart",
  quarantine = "quarantine",
  wfh = "wfh",
  officeId = "officeId",
  notifications = "notifications"
}

export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc"
}

export type SearchableUserConnection = {
  __typename: "SearchableUserConnection";
  items: Array<User | null>;
  nextToken?: string | null;
  total?: number | null;
};

export type CreateOfficeMutation = {
  __typename: "Office";
  id: string;
  country?: string | null;
  state?: string | null;
  city?: string | null;
  address?: string | null;
  parent?: string | null;
  status?: string | null;
  count?: number | null;
  quarantine?: string | null;
  reopening?: string | null;
  ihme_safe?: string | null;
  searchString?: string | null;
  arrival?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  departure?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  break?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  return?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  slots?: {
    __typename: "ModelSlotConnection";
    items: Array<{
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  users?: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      email?: string | null;
      commute?: string | null;
      name?: string | null;
      prefBlock?: Array<string | null> | null;
      prefDays?: Array<string | null> | null;
      prefStart?: Array<string | null> | null;
      quarantine?: boolean | null;
      wfh?: boolean | null;
      officeId: string;
      notifications?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateOfficeMutation = {
  __typename: "Office";
  id: string;
  country?: string | null;
  state?: string | null;
  city?: string | null;
  address?: string | null;
  parent?: string | null;
  status?: string | null;
  count?: number | null;
  quarantine?: string | null;
  reopening?: string | null;
  ihme_safe?: string | null;
  searchString?: string | null;
  arrival?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  departure?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  break?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  return?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  slots?: {
    __typename: "ModelSlotConnection";
    items: Array<{
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  users?: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      email?: string | null;
      commute?: string | null;
      name?: string | null;
      prefBlock?: Array<string | null> | null;
      prefDays?: Array<string | null> | null;
      prefStart?: Array<string | null> | null;
      quarantine?: boolean | null;
      wfh?: boolean | null;
      officeId: string;
      notifications?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteOfficeMutation = {
  __typename: "Office";
  id: string;
  country?: string | null;
  state?: string | null;
  city?: string | null;
  address?: string | null;
  parent?: string | null;
  status?: string | null;
  count?: number | null;
  quarantine?: string | null;
  reopening?: string | null;
  ihme_safe?: string | null;
  searchString?: string | null;
  arrival?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  departure?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  break?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  return?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  slots?: {
    __typename: "ModelSlotConnection";
    items: Array<{
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  users?: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      email?: string | null;
      commute?: string | null;
      name?: string | null;
      prefBlock?: Array<string | null> | null;
      prefDays?: Array<string | null> | null;
      prefStart?: Array<string | null> | null;
      quarantine?: boolean | null;
      wfh?: boolean | null;
      officeId: string;
      notifications?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateSlotMutation = {
  __typename: "Slot";
  id: string;
  start?: string | null;
  date?: string | null;
  type?: string | null;
  interval?: number | null;
  capacity?: number | null;
  filled?: number | null;
  officeId: string;
  office?: {
    __typename: "Office";
    id: string;
    country?: string | null;
    state?: string | null;
    city?: string | null;
    address?: string | null;
    parent?: string | null;
    status?: string | null;
    count?: number | null;
    quarantine?: string | null;
    reopening?: string | null;
    ihme_safe?: string | null;
    searchString?: string | null;
    arrival?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    departure?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    break?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    return?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    slots?: {
      __typename: "ModelSlotConnection";
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateSlotMutation = {
  __typename: "Slot";
  id: string;
  start?: string | null;
  date?: string | null;
  type?: string | null;
  interval?: number | null;
  capacity?: number | null;
  filled?: number | null;
  officeId: string;
  office?: {
    __typename: "Office";
    id: string;
    country?: string | null;
    state?: string | null;
    city?: string | null;
    address?: string | null;
    parent?: string | null;
    status?: string | null;
    count?: number | null;
    quarantine?: string | null;
    reopening?: string | null;
    ihme_safe?: string | null;
    searchString?: string | null;
    arrival?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    departure?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    break?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    return?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    slots?: {
      __typename: "ModelSlotConnection";
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteSlotMutation = {
  __typename: "Slot";
  id: string;
  start?: string | null;
  date?: string | null;
  type?: string | null;
  interval?: number | null;
  capacity?: number | null;
  filled?: number | null;
  officeId: string;
  office?: {
    __typename: "Office";
    id: string;
    country?: string | null;
    state?: string | null;
    city?: string | null;
    address?: string | null;
    parent?: string | null;
    status?: string | null;
    count?: number | null;
    quarantine?: string | null;
    reopening?: string | null;
    ihme_safe?: string | null;
    searchString?: string | null;
    arrival?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    departure?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    break?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    return?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    slots?: {
      __typename: "ModelSlotConnection";
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateNotificationMutation = {
  __typename: "Notification";
  id: string;
  subject: string;
  message: string;
  from: string;
  replyTo?: string | null;
  officeIds?: Array<string | null> | null;
  time?: number | null;
  slotIds?: Array<string | null> | null;
  userIds?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateNotificationMutation = {
  __typename: "Notification";
  id: string;
  subject: string;
  message: string;
  from: string;
  replyTo?: string | null;
  officeIds?: Array<string | null> | null;
  time?: number | null;
  slotIds?: Array<string | null> | null;
  userIds?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteNotificationMutation = {
  __typename: "Notification";
  id: string;
  subject: string;
  message: string;
  from: string;
  replyTo?: string | null;
  officeIds?: Array<string | null> | null;
  time?: number | null;
  slotIds?: Array<string | null> | null;
  userIds?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateUserMutation = {
  __typename: "User";
  id: string;
  email?: string | null;
  commute?: string | null;
  name?: string | null;
  prefBlock?: Array<string | null> | null;
  prefDays?: Array<string | null> | null;
  prefStart?: Array<string | null> | null;
  quarantine?: boolean | null;
  wfh?: boolean | null;
  officeId: string;
  office?: {
    __typename: "Office";
    id: string;
    country?: string | null;
    state?: string | null;
    city?: string | null;
    address?: string | null;
    parent?: string | null;
    status?: string | null;
    count?: number | null;
    quarantine?: string | null;
    reopening?: string | null;
    ihme_safe?: string | null;
    searchString?: string | null;
    arrival?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    departure?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    break?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    return?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    slots?: {
      __typename: "ModelSlotConnection";
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  schedules?: {
    __typename: "ModelScheduleConnection";
    items: Array<{
      __typename: "Schedule";
      id: string;
      date?: string | null;
      arrivalId: string;
      departureId: string;
      breakId: string;
      returnId: string;
      userId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  notifications?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateUserMutation = {
  __typename: "User";
  id: string;
  email?: string | null;
  commute?: string | null;
  name?: string | null;
  prefBlock?: Array<string | null> | null;
  prefDays?: Array<string | null> | null;
  prefStart?: Array<string | null> | null;
  quarantine?: boolean | null;
  wfh?: boolean | null;
  officeId: string;
  office?: {
    __typename: "Office";
    id: string;
    country?: string | null;
    state?: string | null;
    city?: string | null;
    address?: string | null;
    parent?: string | null;
    status?: string | null;
    count?: number | null;
    quarantine?: string | null;
    reopening?: string | null;
    ihme_safe?: string | null;
    searchString?: string | null;
    arrival?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    departure?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    break?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    return?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    slots?: {
      __typename: "ModelSlotConnection";
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  schedules?: {
    __typename: "ModelScheduleConnection";
    items: Array<{
      __typename: "Schedule";
      id: string;
      date?: string | null;
      arrivalId: string;
      departureId: string;
      breakId: string;
      returnId: string;
      userId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  notifications?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteUserMutation = {
  __typename: "User";
  id: string;
  email?: string | null;
  commute?: string | null;
  name?: string | null;
  prefBlock?: Array<string | null> | null;
  prefDays?: Array<string | null> | null;
  prefStart?: Array<string | null> | null;
  quarantine?: boolean | null;
  wfh?: boolean | null;
  officeId: string;
  office?: {
    __typename: "Office";
    id: string;
    country?: string | null;
    state?: string | null;
    city?: string | null;
    address?: string | null;
    parent?: string | null;
    status?: string | null;
    count?: number | null;
    quarantine?: string | null;
    reopening?: string | null;
    ihme_safe?: string | null;
    searchString?: string | null;
    arrival?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    departure?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    break?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    return?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    slots?: {
      __typename: "ModelSlotConnection";
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  schedules?: {
    __typename: "ModelScheduleConnection";
    items: Array<{
      __typename: "Schedule";
      id: string;
      date?: string | null;
      arrivalId: string;
      departureId: string;
      breakId: string;
      returnId: string;
      userId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  notifications?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateScheduleMutation = {
  __typename: "Schedule";
  id: string;
  date?: string | null;
  arrivalId: string;
  arrival?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  departureId: string;
  departure?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  breakId: string;
  break?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  returnId: string;
  return?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    email?: string | null;
    commute?: string | null;
    name?: string | null;
    prefBlock?: Array<string | null> | null;
    prefDays?: Array<string | null> | null;
    prefStart?: Array<string | null> | null;
    quarantine?: boolean | null;
    wfh?: boolean | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    schedules?: {
      __typename: "ModelScheduleConnection";
      nextToken?: string | null;
    } | null;
    notifications?: Array<string | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  events?: {
    __typename: "ModelEventConnection";
    items: Array<{
      __typename: "Event";
      id: string;
      type: string;
      note?: string | null;
      time?: number | null;
      entity?: string | null;
      owner?: string | null;
      scheduleId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateScheduleMutation = {
  __typename: "Schedule";
  id: string;
  date?: string | null;
  arrivalId: string;
  arrival?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  departureId: string;
  departure?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  breakId: string;
  break?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  returnId: string;
  return?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    email?: string | null;
    commute?: string | null;
    name?: string | null;
    prefBlock?: Array<string | null> | null;
    prefDays?: Array<string | null> | null;
    prefStart?: Array<string | null> | null;
    quarantine?: boolean | null;
    wfh?: boolean | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    schedules?: {
      __typename: "ModelScheduleConnection";
      nextToken?: string | null;
    } | null;
    notifications?: Array<string | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  events?: {
    __typename: "ModelEventConnection";
    items: Array<{
      __typename: "Event";
      id: string;
      type: string;
      note?: string | null;
      time?: number | null;
      entity?: string | null;
      owner?: string | null;
      scheduleId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteScheduleMutation = {
  __typename: "Schedule";
  id: string;
  date?: string | null;
  arrivalId: string;
  arrival?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  departureId: string;
  departure?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  breakId: string;
  break?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  returnId: string;
  return?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    email?: string | null;
    commute?: string | null;
    name?: string | null;
    prefBlock?: Array<string | null> | null;
    prefDays?: Array<string | null> | null;
    prefStart?: Array<string | null> | null;
    quarantine?: boolean | null;
    wfh?: boolean | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    schedules?: {
      __typename: "ModelScheduleConnection";
      nextToken?: string | null;
    } | null;
    notifications?: Array<string | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  events?: {
    __typename: "ModelEventConnection";
    items: Array<{
      __typename: "Event";
      id: string;
      type: string;
      note?: string | null;
      time?: number | null;
      entity?: string | null;
      owner?: string | null;
      scheduleId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateEventMutation = {
  __typename: "Event";
  id: string;
  type: string;
  note?: string | null;
  time?: number | null;
  entity?: string | null;
  owner?: string | null;
  scheduleId: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateEventMutation = {
  __typename: "Event";
  id: string;
  type: string;
  note?: string | null;
  time?: number | null;
  entity?: string | null;
  owner?: string | null;
  scheduleId: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteEventMutation = {
  __typename: "Event";
  id: string;
  type: string;
  note?: string | null;
  time?: number | null;
  entity?: string | null;
  owner?: string | null;
  scheduleId: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateVendorMutation = {
  __typename: "Vendor";
  id: string;
  type?: string | null;
  status?: string | null;
  options?: Array<string | null> | null;
  name?: string | null;
  visible?: boolean | null;
  icon?: string | null;
  src?: string | null;
  officeId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateVendorMutation = {
  __typename: "Vendor";
  id: string;
  type?: string | null;
  status?: string | null;
  options?: Array<string | null> | null;
  name?: string | null;
  visible?: boolean | null;
  icon?: string | null;
  src?: string | null;
  officeId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteVendorMutation = {
  __typename: "Vendor";
  id: string;
  type?: string | null;
  status?: string | null;
  options?: Array<string | null> | null;
  name?: string | null;
  visible?: boolean | null;
  icon?: string | null;
  src?: string | null;
  officeId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateBeaconMutation = {
  __typename: "Beacon";
  id: string;
  uuid: string;
  name?: string | null;
  message?: string | null;
  type?: string | null;
  callback?: string | null;
  officeId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateBeaconMutation = {
  __typename: "Beacon";
  id: string;
  uuid: string;
  name?: string | null;
  message?: string | null;
  type?: string | null;
  callback?: string | null;
  officeId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteBeaconMutation = {
  __typename: "Beacon";
  id: string;
  uuid: string;
  name?: string | null;
  message?: string | null;
  type?: string | null;
  callback?: string | null;
  officeId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreatePublishTestMutation = {
  __typename: "PublishTest";
  id: string;
  name: string;
  description?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdatePublishTestMutation = {
  __typename: "PublishTest";
  id: string;
  name: string;
  description?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeletePublishTestMutation = {
  __typename: "PublishTest";
  id: string;
  name: string;
  description?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type GetOfficeQuery = {
  __typename: "Office";
  id: string;
  country?: string | null;
  state?: string | null;
  city?: string | null;
  address?: string | null;
  parent?: string | null;
  status?: string | null;
  count?: number | null;
  quarantine?: string | null;
  reopening?: string | null;
  ihme_safe?: string | null;
  searchString?: string | null;
  arrival?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  departure?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  break?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  return?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  slots?: {
    __typename: "ModelSlotConnection";
    items: Array<{
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  users?: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      email?: string | null;
      commute?: string | null;
      name?: string | null;
      prefBlock?: Array<string | null> | null;
      prefDays?: Array<string | null> | null;
      prefStart?: Array<string | null> | null;
      quarantine?: boolean | null;
      wfh?: boolean | null;
      officeId: string;
      notifications?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListOfficesQuery = {
  __typename: "ModelOfficeConnection";
  items: Array<{
    __typename: "Office";
    id: string;
    country?: string | null;
    state?: string | null;
    city?: string | null;
    address?: string | null;
    parent?: string | null;
    status?: string | null;
    count?: number | null;
    quarantine?: string | null;
    reopening?: string | null;
    ihme_safe?: string | null;
    searchString?: string | null;
    arrival?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    departure?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    break?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    return?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    slots?: {
      __typename: "ModelSlotConnection";
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetSlotQuery = {
  __typename: "Slot";
  id: string;
  start?: string | null;
  date?: string | null;
  type?: string | null;
  interval?: number | null;
  capacity?: number | null;
  filled?: number | null;
  officeId: string;
  office?: {
    __typename: "Office";
    id: string;
    country?: string | null;
    state?: string | null;
    city?: string | null;
    address?: string | null;
    parent?: string | null;
    status?: string | null;
    count?: number | null;
    quarantine?: string | null;
    reopening?: string | null;
    ihme_safe?: string | null;
    searchString?: string | null;
    arrival?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    departure?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    break?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    return?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    slots?: {
      __typename: "ModelSlotConnection";
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListSlotsQuery = {
  __typename: "ModelSlotConnection";
  items: Array<{
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetNotificationQuery = {
  __typename: "Notification";
  id: string;
  subject: string;
  message: string;
  from: string;
  replyTo?: string | null;
  officeIds?: Array<string | null> | null;
  time?: number | null;
  slotIds?: Array<string | null> | null;
  userIds?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type ListNotificationsQuery = {
  __typename: "ModelNotificationConnection";
  items: Array<{
    __typename: "Notification";
    id: string;
    subject: string;
    message: string;
    from: string;
    replyTo?: string | null;
    officeIds?: Array<string | null> | null;
    time?: number | null;
    slotIds?: Array<string | null> | null;
    userIds?: Array<string | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetUserQuery = {
  __typename: "User";
  id: string;
  email?: string | null;
  commute?: string | null;
  name?: string | null;
  prefBlock?: Array<string | null> | null;
  prefDays?: Array<string | null> | null;
  prefStart?: Array<string | null> | null;
  quarantine?: boolean | null;
  wfh?: boolean | null;
  officeId: string;
  office?: {
    __typename: "Office";
    id: string;
    country?: string | null;
    state?: string | null;
    city?: string | null;
    address?: string | null;
    parent?: string | null;
    status?: string | null;
    count?: number | null;
    quarantine?: string | null;
    reopening?: string | null;
    ihme_safe?: string | null;
    searchString?: string | null;
    arrival?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    departure?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    break?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    return?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    slots?: {
      __typename: "ModelSlotConnection";
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  schedules?: {
    __typename: "ModelScheduleConnection";
    items: Array<{
      __typename: "Schedule";
      id: string;
      date?: string | null;
      arrivalId: string;
      departureId: string;
      breakId: string;
      returnId: string;
      userId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  notifications?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type ListUsersQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    id: string;
    email?: string | null;
    commute?: string | null;
    name?: string | null;
    prefBlock?: Array<string | null> | null;
    prefDays?: Array<string | null> | null;
    prefStart?: Array<string | null> | null;
    quarantine?: boolean | null;
    wfh?: boolean | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    schedules?: {
      __typename: "ModelScheduleConnection";
      nextToken?: string | null;
    } | null;
    notifications?: Array<string | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetScheduleQuery = {
  __typename: "Schedule";
  id: string;
  date?: string | null;
  arrivalId: string;
  arrival?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  departureId: string;
  departure?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  breakId: string;
  break?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  returnId: string;
  return?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    email?: string | null;
    commute?: string | null;
    name?: string | null;
    prefBlock?: Array<string | null> | null;
    prefDays?: Array<string | null> | null;
    prefStart?: Array<string | null> | null;
    quarantine?: boolean | null;
    wfh?: boolean | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    schedules?: {
      __typename: "ModelScheduleConnection";
      nextToken?: string | null;
    } | null;
    notifications?: Array<string | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  events?: {
    __typename: "ModelEventConnection";
    items: Array<{
      __typename: "Event";
      id: string;
      type: string;
      note?: string | null;
      time?: number | null;
      entity?: string | null;
      owner?: string | null;
      scheduleId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListSchedulesQuery = {
  __typename: "ModelScheduleConnection";
  items: Array<{
    __typename: "Schedule";
    id: string;
    date?: string | null;
    arrivalId: string;
    arrival?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    departureId: string;
    departure?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    breakId: string;
    break?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    returnId: string;
    return?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    userId: string;
    user?: {
      __typename: "User";
      id: string;
      email?: string | null;
      commute?: string | null;
      name?: string | null;
      prefBlock?: Array<string | null> | null;
      prefDays?: Array<string | null> | null;
      prefStart?: Array<string | null> | null;
      quarantine?: boolean | null;
      wfh?: boolean | null;
      officeId: string;
      notifications?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    events?: {
      __typename: "ModelEventConnection";
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetEventQuery = {
  __typename: "Event";
  id: string;
  type: string;
  note?: string | null;
  time?: number | null;
  entity?: string | null;
  owner?: string | null;
  scheduleId: string;
  createdAt: string;
  updatedAt: string;
};

export type ListEventsQuery = {
  __typename: "ModelEventConnection";
  items: Array<{
    __typename: "Event";
    id: string;
    type: string;
    note?: string | null;
    time?: number | null;
    entity?: string | null;
    owner?: string | null;
    scheduleId: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetVendorQuery = {
  __typename: "Vendor";
  id: string;
  type?: string | null;
  status?: string | null;
  options?: Array<string | null> | null;
  name?: string | null;
  visible?: boolean | null;
  icon?: string | null;
  src?: string | null;
  officeId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListVendorsQuery = {
  __typename: "ModelVendorConnection";
  items: Array<{
    __typename: "Vendor";
    id: string;
    type?: string | null;
    status?: string | null;
    options?: Array<string | null> | null;
    name?: string | null;
    visible?: boolean | null;
    icon?: string | null;
    src?: string | null;
    officeId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetBeaconQuery = {
  __typename: "Beacon";
  id: string;
  uuid: string;
  name?: string | null;
  message?: string | null;
  type?: string | null;
  callback?: string | null;
  officeId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListBeaconsQuery = {
  __typename: "ModelBeaconConnection";
  items: Array<{
    __typename: "Beacon";
    id: string;
    uuid: string;
    name?: string | null;
    message?: string | null;
    type?: string | null;
    callback?: string | null;
    officeId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetPublishTestQuery = {
  __typename: "PublishTest";
  id: string;
  name: string;
  description?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListPublishTestsQuery = {
  __typename: "ModelPublishTestConnection";
  items: Array<{
    __typename: "PublishTest";
    id: string;
    name: string;
    description?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListSlotsByOfficeByStartQuery = {
  __typename: "ModelSlotConnection";
  items: Array<{
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListSchedulesByUserByDateQuery = {
  __typename: "ModelScheduleConnection";
  items: Array<{
    __typename: "Schedule";
    id: string;
    date?: string | null;
    arrivalId: string;
    arrival?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    departureId: string;
    departure?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    breakId: string;
    break?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    returnId: string;
    return?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    userId: string;
    user?: {
      __typename: "User";
      id: string;
      email?: string | null;
      commute?: string | null;
      name?: string | null;
      prefBlock?: Array<string | null> | null;
      prefDays?: Array<string | null> | null;
      prefStart?: Array<string | null> | null;
      quarantine?: boolean | null;
      wfh?: boolean | null;
      officeId: string;
      notifications?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    events?: {
      __typename: "ModelEventConnection";
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListSchedulesByDateQuery = {
  __typename: "ModelScheduleConnection";
  items: Array<{
    __typename: "Schedule";
    id: string;
    date?: string | null;
    arrivalId: string;
    arrival?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    departureId: string;
    departure?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    breakId: string;
    break?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    returnId: string;
    return?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    userId: string;
    user?: {
      __typename: "User";
      id: string;
      email?: string | null;
      commute?: string | null;
      name?: string | null;
      prefBlock?: Array<string | null> | null;
      prefDays?: Array<string | null> | null;
      prefStart?: Array<string | null> | null;
      quarantine?: boolean | null;
      wfh?: boolean | null;
      officeId: string;
      notifications?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    events?: {
      __typename: "ModelEventConnection";
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListSchedulesByArrivalIdQuery = {
  __typename: "ModelScheduleConnection";
  items: Array<{
    __typename: "Schedule";
    id: string;
    date?: string | null;
    arrivalId: string;
    arrival?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    departureId: string;
    departure?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    breakId: string;
    break?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    returnId: string;
    return?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    userId: string;
    user?: {
      __typename: "User";
      id: string;
      email?: string | null;
      commute?: string | null;
      name?: string | null;
      prefBlock?: Array<string | null> | null;
      prefDays?: Array<string | null> | null;
      prefStart?: Array<string | null> | null;
      quarantine?: boolean | null;
      wfh?: boolean | null;
      officeId: string;
      notifications?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    events?: {
      __typename: "ModelEventConnection";
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListSchedulesByDepartureIdQuery = {
  __typename: "ModelScheduleConnection";
  items: Array<{
    __typename: "Schedule";
    id: string;
    date?: string | null;
    arrivalId: string;
    arrival?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    departureId: string;
    departure?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    breakId: string;
    break?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    returnId: string;
    return?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    userId: string;
    user?: {
      __typename: "User";
      id: string;
      email?: string | null;
      commute?: string | null;
      name?: string | null;
      prefBlock?: Array<string | null> | null;
      prefDays?: Array<string | null> | null;
      prefStart?: Array<string | null> | null;
      quarantine?: boolean | null;
      wfh?: boolean | null;
      officeId: string;
      notifications?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    events?: {
      __typename: "ModelEventConnection";
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListSchedulesByBreakIdQuery = {
  __typename: "ModelScheduleConnection";
  items: Array<{
    __typename: "Schedule";
    id: string;
    date?: string | null;
    arrivalId: string;
    arrival?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    departureId: string;
    departure?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    breakId: string;
    break?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    returnId: string;
    return?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    userId: string;
    user?: {
      __typename: "User";
      id: string;
      email?: string | null;
      commute?: string | null;
      name?: string | null;
      prefBlock?: Array<string | null> | null;
      prefDays?: Array<string | null> | null;
      prefStart?: Array<string | null> | null;
      quarantine?: boolean | null;
      wfh?: boolean | null;
      officeId: string;
      notifications?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    events?: {
      __typename: "ModelEventConnection";
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListSchedulesByReturnIdQuery = {
  __typename: "ModelScheduleConnection";
  items: Array<{
    __typename: "Schedule";
    id: string;
    date?: string | null;
    arrivalId: string;
    arrival?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    departureId: string;
    departure?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    breakId: string;
    break?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    returnId: string;
    return?: {
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    userId: string;
    user?: {
      __typename: "User";
      id: string;
      email?: string | null;
      commute?: string | null;
      name?: string | null;
      prefBlock?: Array<string | null> | null;
      prefDays?: Array<string | null> | null;
      prefStart?: Array<string | null> | null;
      quarantine?: boolean | null;
      wfh?: boolean | null;
      officeId: string;
      notifications?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    events?: {
      __typename: "ModelEventConnection";
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListVendorsByOfficeQuery = {
  __typename: "ModelVendorConnection";
  items: Array<{
    __typename: "Vendor";
    id: string;
    type?: string | null;
    status?: string | null;
    options?: Array<string | null> | null;
    name?: string | null;
    visible?: boolean | null;
    icon?: string | null;
    src?: string | null;
    officeId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListBeaconsByOfficeQuery = {
  __typename: "ModelBeaconConnection";
  items: Array<{
    __typename: "Beacon";
    id: string;
    uuid: string;
    name?: string | null;
    message?: string | null;
    type?: string | null;
    callback?: string | null;
    officeId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type SearchUsersQuery = {
  __typename: "SearchableUserConnection";
  items: Array<{
    __typename: "User";
    id: string;
    email?: string | null;
    commute?: string | null;
    name?: string | null;
    prefBlock?: Array<string | null> | null;
    prefDays?: Array<string | null> | null;
    prefStart?: Array<string | null> | null;
    quarantine?: boolean | null;
    wfh?: boolean | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    schedules?: {
      __typename: "ModelScheduleConnection";
      nextToken?: string | null;
    } | null;
    notifications?: Array<string | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  total?: number | null;
};

export type OnCreateOfficeSubscription = {
  __typename: "Office";
  id: string;
  country?: string | null;
  state?: string | null;
  city?: string | null;
  address?: string | null;
  parent?: string | null;
  status?: string | null;
  count?: number | null;
  quarantine?: string | null;
  reopening?: string | null;
  ihme_safe?: string | null;
  searchString?: string | null;
  arrival?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  departure?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  break?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  return?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  slots?: {
    __typename: "ModelSlotConnection";
    items: Array<{
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  users?: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      email?: string | null;
      commute?: string | null;
      name?: string | null;
      prefBlock?: Array<string | null> | null;
      prefDays?: Array<string | null> | null;
      prefStart?: Array<string | null> | null;
      quarantine?: boolean | null;
      wfh?: boolean | null;
      officeId: string;
      notifications?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateOfficeSubscription = {
  __typename: "Office";
  id: string;
  country?: string | null;
  state?: string | null;
  city?: string | null;
  address?: string | null;
  parent?: string | null;
  status?: string | null;
  count?: number | null;
  quarantine?: string | null;
  reopening?: string | null;
  ihme_safe?: string | null;
  searchString?: string | null;
  arrival?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  departure?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  break?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  return?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  slots?: {
    __typename: "ModelSlotConnection";
    items: Array<{
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  users?: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      email?: string | null;
      commute?: string | null;
      name?: string | null;
      prefBlock?: Array<string | null> | null;
      prefDays?: Array<string | null> | null;
      prefStart?: Array<string | null> | null;
      quarantine?: boolean | null;
      wfh?: boolean | null;
      officeId: string;
      notifications?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteOfficeSubscription = {
  __typename: "Office";
  id: string;
  country?: string | null;
  state?: string | null;
  city?: string | null;
  address?: string | null;
  parent?: string | null;
  status?: string | null;
  count?: number | null;
  quarantine?: string | null;
  reopening?: string | null;
  ihme_safe?: string | null;
  searchString?: string | null;
  arrival?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  departure?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  break?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  return?: {
    __typename: "SlotConfig";
    enabled: boolean;
    capacity: number;
    start: string;
    end: string;
    type: string;
    blocks?: number | null;
    interval?: number | null;
  } | null;
  slots?: {
    __typename: "ModelSlotConnection";
    items: Array<{
      __typename: "Slot";
      id: string;
      start?: string | null;
      date?: string | null;
      type?: string | null;
      interval?: number | null;
      capacity?: number | null;
      filled?: number | null;
      officeId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  users?: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      email?: string | null;
      commute?: string | null;
      name?: string | null;
      prefBlock?: Array<string | null> | null;
      prefDays?: Array<string | null> | null;
      prefStart?: Array<string | null> | null;
      quarantine?: boolean | null;
      wfh?: boolean | null;
      officeId: string;
      notifications?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateSlotSubscription = {
  __typename: "Slot";
  id: string;
  start?: string | null;
  date?: string | null;
  type?: string | null;
  interval?: number | null;
  capacity?: number | null;
  filled?: number | null;
  officeId: string;
  office?: {
    __typename: "Office";
    id: string;
    country?: string | null;
    state?: string | null;
    city?: string | null;
    address?: string | null;
    parent?: string | null;
    status?: string | null;
    count?: number | null;
    quarantine?: string | null;
    reopening?: string | null;
    ihme_safe?: string | null;
    searchString?: string | null;
    arrival?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    departure?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    break?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    return?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    slots?: {
      __typename: "ModelSlotConnection";
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateSlotSubscription = {
  __typename: "Slot";
  id: string;
  start?: string | null;
  date?: string | null;
  type?: string | null;
  interval?: number | null;
  capacity?: number | null;
  filled?: number | null;
  officeId: string;
  office?: {
    __typename: "Office";
    id: string;
    country?: string | null;
    state?: string | null;
    city?: string | null;
    address?: string | null;
    parent?: string | null;
    status?: string | null;
    count?: number | null;
    quarantine?: string | null;
    reopening?: string | null;
    ihme_safe?: string | null;
    searchString?: string | null;
    arrival?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    departure?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    break?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    return?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    slots?: {
      __typename: "ModelSlotConnection";
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteSlotSubscription = {
  __typename: "Slot";
  id: string;
  start?: string | null;
  date?: string | null;
  type?: string | null;
  interval?: number | null;
  capacity?: number | null;
  filled?: number | null;
  officeId: string;
  office?: {
    __typename: "Office";
    id: string;
    country?: string | null;
    state?: string | null;
    city?: string | null;
    address?: string | null;
    parent?: string | null;
    status?: string | null;
    count?: number | null;
    quarantine?: string | null;
    reopening?: string | null;
    ihme_safe?: string | null;
    searchString?: string | null;
    arrival?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    departure?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    break?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    return?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    slots?: {
      __typename: "ModelSlotConnection";
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateNotificationSubscription = {
  __typename: "Notification";
  id: string;
  subject: string;
  message: string;
  from: string;
  replyTo?: string | null;
  officeIds?: Array<string | null> | null;
  time?: number | null;
  slotIds?: Array<string | null> | null;
  userIds?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateNotificationSubscription = {
  __typename: "Notification";
  id: string;
  subject: string;
  message: string;
  from: string;
  replyTo?: string | null;
  officeIds?: Array<string | null> | null;
  time?: number | null;
  slotIds?: Array<string | null> | null;
  userIds?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteNotificationSubscription = {
  __typename: "Notification";
  id: string;
  subject: string;
  message: string;
  from: string;
  replyTo?: string | null;
  officeIds?: Array<string | null> | null;
  time?: number | null;
  slotIds?: Array<string | null> | null;
  userIds?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateUserSubscription = {
  __typename: "User";
  id: string;
  email?: string | null;
  commute?: string | null;
  name?: string | null;
  prefBlock?: Array<string | null> | null;
  prefDays?: Array<string | null> | null;
  prefStart?: Array<string | null> | null;
  quarantine?: boolean | null;
  wfh?: boolean | null;
  officeId: string;
  office?: {
    __typename: "Office";
    id: string;
    country?: string | null;
    state?: string | null;
    city?: string | null;
    address?: string | null;
    parent?: string | null;
    status?: string | null;
    count?: number | null;
    quarantine?: string | null;
    reopening?: string | null;
    ihme_safe?: string | null;
    searchString?: string | null;
    arrival?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    departure?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    break?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    return?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    slots?: {
      __typename: "ModelSlotConnection";
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  schedules?: {
    __typename: "ModelScheduleConnection";
    items: Array<{
      __typename: "Schedule";
      id: string;
      date?: string | null;
      arrivalId: string;
      departureId: string;
      breakId: string;
      returnId: string;
      userId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  notifications?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateUserSubscription = {
  __typename: "User";
  id: string;
  email?: string | null;
  commute?: string | null;
  name?: string | null;
  prefBlock?: Array<string | null> | null;
  prefDays?: Array<string | null> | null;
  prefStart?: Array<string | null> | null;
  quarantine?: boolean | null;
  wfh?: boolean | null;
  officeId: string;
  office?: {
    __typename: "Office";
    id: string;
    country?: string | null;
    state?: string | null;
    city?: string | null;
    address?: string | null;
    parent?: string | null;
    status?: string | null;
    count?: number | null;
    quarantine?: string | null;
    reopening?: string | null;
    ihme_safe?: string | null;
    searchString?: string | null;
    arrival?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    departure?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    break?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    return?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    slots?: {
      __typename: "ModelSlotConnection";
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  schedules?: {
    __typename: "ModelScheduleConnection";
    items: Array<{
      __typename: "Schedule";
      id: string;
      date?: string | null;
      arrivalId: string;
      departureId: string;
      breakId: string;
      returnId: string;
      userId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  notifications?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteUserSubscription = {
  __typename: "User";
  id: string;
  email?: string | null;
  commute?: string | null;
  name?: string | null;
  prefBlock?: Array<string | null> | null;
  prefDays?: Array<string | null> | null;
  prefStart?: Array<string | null> | null;
  quarantine?: boolean | null;
  wfh?: boolean | null;
  officeId: string;
  office?: {
    __typename: "Office";
    id: string;
    country?: string | null;
    state?: string | null;
    city?: string | null;
    address?: string | null;
    parent?: string | null;
    status?: string | null;
    count?: number | null;
    quarantine?: string | null;
    reopening?: string | null;
    ihme_safe?: string | null;
    searchString?: string | null;
    arrival?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    departure?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    break?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    return?: {
      __typename: "SlotConfig";
      enabled: boolean;
      capacity: number;
      start: string;
      end: string;
      type: string;
      blocks?: number | null;
      interval?: number | null;
    } | null;
    slots?: {
      __typename: "ModelSlotConnection";
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  schedules?: {
    __typename: "ModelScheduleConnection";
    items: Array<{
      __typename: "Schedule";
      id: string;
      date?: string | null;
      arrivalId: string;
      departureId: string;
      breakId: string;
      returnId: string;
      userId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  notifications?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateScheduleSubscription = {
  __typename: "Schedule";
  id: string;
  date?: string | null;
  arrivalId: string;
  arrival?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  departureId: string;
  departure?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  breakId: string;
  break?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  returnId: string;
  return?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    email?: string | null;
    commute?: string | null;
    name?: string | null;
    prefBlock?: Array<string | null> | null;
    prefDays?: Array<string | null> | null;
    prefStart?: Array<string | null> | null;
    quarantine?: boolean | null;
    wfh?: boolean | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    schedules?: {
      __typename: "ModelScheduleConnection";
      nextToken?: string | null;
    } | null;
    notifications?: Array<string | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  events?: {
    __typename: "ModelEventConnection";
    items: Array<{
      __typename: "Event";
      id: string;
      type: string;
      note?: string | null;
      time?: number | null;
      entity?: string | null;
      owner?: string | null;
      scheduleId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateScheduleSubscription = {
  __typename: "Schedule";
  id: string;
  date?: string | null;
  arrivalId: string;
  arrival?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  departureId: string;
  departure?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  breakId: string;
  break?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  returnId: string;
  return?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    email?: string | null;
    commute?: string | null;
    name?: string | null;
    prefBlock?: Array<string | null> | null;
    prefDays?: Array<string | null> | null;
    prefStart?: Array<string | null> | null;
    quarantine?: boolean | null;
    wfh?: boolean | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    schedules?: {
      __typename: "ModelScheduleConnection";
      nextToken?: string | null;
    } | null;
    notifications?: Array<string | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  events?: {
    __typename: "ModelEventConnection";
    items: Array<{
      __typename: "Event";
      id: string;
      type: string;
      note?: string | null;
      time?: number | null;
      entity?: string | null;
      owner?: string | null;
      scheduleId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteScheduleSubscription = {
  __typename: "Schedule";
  id: string;
  date?: string | null;
  arrivalId: string;
  arrival?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  departureId: string;
  departure?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  breakId: string;
  break?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  returnId: string;
  return?: {
    __typename: "Slot";
    id: string;
    start?: string | null;
    date?: string | null;
    type?: string | null;
    interval?: number | null;
    capacity?: number | null;
    filled?: number | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  userId: string;
  user?: {
    __typename: "User";
    id: string;
    email?: string | null;
    commute?: string | null;
    name?: string | null;
    prefBlock?: Array<string | null> | null;
    prefDays?: Array<string | null> | null;
    prefStart?: Array<string | null> | null;
    quarantine?: boolean | null;
    wfh?: boolean | null;
    officeId: string;
    office?: {
      __typename: "Office";
      id: string;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      address?: string | null;
      parent?: string | null;
      status?: string | null;
      count?: number | null;
      quarantine?: string | null;
      reopening?: string | null;
      ihme_safe?: string | null;
      searchString?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    schedules?: {
      __typename: "ModelScheduleConnection";
      nextToken?: string | null;
    } | null;
    notifications?: Array<string | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  events?: {
    __typename: "ModelEventConnection";
    items: Array<{
      __typename: "Event";
      id: string;
      type: string;
      note?: string | null;
      time?: number | null;
      entity?: string | null;
      owner?: string | null;
      scheduleId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateEventSubscription = {
  __typename: "Event";
  id: string;
  type: string;
  note?: string | null;
  time?: number | null;
  entity?: string | null;
  owner?: string | null;
  scheduleId: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateEventSubscription = {
  __typename: "Event";
  id: string;
  type: string;
  note?: string | null;
  time?: number | null;
  entity?: string | null;
  owner?: string | null;
  scheduleId: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteEventSubscription = {
  __typename: "Event";
  id: string;
  type: string;
  note?: string | null;
  time?: number | null;
  entity?: string | null;
  owner?: string | null;
  scheduleId: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateVendorSubscription = {
  __typename: "Vendor";
  id: string;
  type?: string | null;
  status?: string | null;
  options?: Array<string | null> | null;
  name?: string | null;
  visible?: boolean | null;
  icon?: string | null;
  src?: string | null;
  officeId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateVendorSubscription = {
  __typename: "Vendor";
  id: string;
  type?: string | null;
  status?: string | null;
  options?: Array<string | null> | null;
  name?: string | null;
  visible?: boolean | null;
  icon?: string | null;
  src?: string | null;
  officeId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteVendorSubscription = {
  __typename: "Vendor";
  id: string;
  type?: string | null;
  status?: string | null;
  options?: Array<string | null> | null;
  name?: string | null;
  visible?: boolean | null;
  icon?: string | null;
  src?: string | null;
  officeId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateBeaconSubscription = {
  __typename: "Beacon";
  id: string;
  uuid: string;
  name?: string | null;
  message?: string | null;
  type?: string | null;
  callback?: string | null;
  officeId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateBeaconSubscription = {
  __typename: "Beacon";
  id: string;
  uuid: string;
  name?: string | null;
  message?: string | null;
  type?: string | null;
  callback?: string | null;
  officeId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteBeaconSubscription = {
  __typename: "Beacon";
  id: string;
  uuid: string;
  name?: string | null;
  message?: string | null;
  type?: string | null;
  callback?: string | null;
  officeId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreatePublishTestSubscription = {
  __typename: "PublishTest";
  id: string;
  name: string;
  description?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdatePublishTestSubscription = {
  __typename: "PublishTest";
  id: string;
  name: string;
  description?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeletePublishTestSubscription = {
  __typename: "PublishTest";
  id: string;
  name: string;
  description?: string | null;
  createdAt: string;
  updatedAt: string;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateOffice(
    input: CreateOfficeInput,
    condition?: ModelOfficeConditionInput
  ): Promise<CreateOfficeMutation> {
    const statement = `mutation CreateOffice($input: CreateOfficeInput!, $condition: ModelOfficeConditionInput) {
        createOffice(input: $input, condition: $condition) {
          __typename
          id
          country
          state
          city
          address
          parent
          status
          count
          quarantine
          reopening
          ihme_safe
          searchString
          arrival {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          departure {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          break {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          return {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          slots {
            __typename
            items {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            __typename
            items {
              __typename
              id
              email
              commute
              name
              prefBlock
              prefDays
              prefStart
              quarantine
              wfh
              officeId
              notifications
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOfficeMutation>response.data.createOffice;
  }
  async UpdateOffice(
    input: UpdateOfficeInput,
    condition?: ModelOfficeConditionInput
  ): Promise<UpdateOfficeMutation> {
    const statement = `mutation UpdateOffice($input: UpdateOfficeInput!, $condition: ModelOfficeConditionInput) {
        updateOffice(input: $input, condition: $condition) {
          __typename
          id
          country
          state
          city
          address
          parent
          status
          count
          quarantine
          reopening
          ihme_safe
          searchString
          arrival {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          departure {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          break {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          return {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          slots {
            __typename
            items {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            __typename
            items {
              __typename
              id
              email
              commute
              name
              prefBlock
              prefDays
              prefStart
              quarantine
              wfh
              officeId
              notifications
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOfficeMutation>response.data.updateOffice;
  }
  async DeleteOffice(
    input: DeleteOfficeInput,
    condition?: ModelOfficeConditionInput
  ): Promise<DeleteOfficeMutation> {
    const statement = `mutation DeleteOffice($input: DeleteOfficeInput!, $condition: ModelOfficeConditionInput) {
        deleteOffice(input: $input, condition: $condition) {
          __typename
          id
          country
          state
          city
          address
          parent
          status
          count
          quarantine
          reopening
          ihme_safe
          searchString
          arrival {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          departure {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          break {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          return {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          slots {
            __typename
            items {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            __typename
            items {
              __typename
              id
              email
              commute
              name
              prefBlock
              prefDays
              prefStart
              quarantine
              wfh
              officeId
              notifications
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOfficeMutation>response.data.deleteOffice;
  }
  async CreateSlot(
    input: CreateSlotInput,
    condition?: ModelSlotConditionInput
  ): Promise<CreateSlotMutation> {
    const statement = `mutation CreateSlot($input: CreateSlotInput!, $condition: ModelSlotConditionInput) {
        createSlot(input: $input, condition: $condition) {
          __typename
          id
          start
          date
          type
          interval
          capacity
          filled
          officeId
          office {
            __typename
            id
            country
            state
            city
            address
            parent
            status
            count
            quarantine
            reopening
            ihme_safe
            searchString
            arrival {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            departure {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            break {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            return {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            slots {
              __typename
              nextToken
            }
            users {
              __typename
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSlotMutation>response.data.createSlot;
  }
  async UpdateSlot(
    input: UpdateSlotInput,
    condition?: ModelSlotConditionInput
  ): Promise<UpdateSlotMutation> {
    const statement = `mutation UpdateSlot($input: UpdateSlotInput!, $condition: ModelSlotConditionInput) {
        updateSlot(input: $input, condition: $condition) {
          __typename
          id
          start
          date
          type
          interval
          capacity
          filled
          officeId
          office {
            __typename
            id
            country
            state
            city
            address
            parent
            status
            count
            quarantine
            reopening
            ihme_safe
            searchString
            arrival {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            departure {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            break {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            return {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            slots {
              __typename
              nextToken
            }
            users {
              __typename
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSlotMutation>response.data.updateSlot;
  }
  async DeleteSlot(
    input: DeleteSlotInput,
    condition?: ModelSlotConditionInput
  ): Promise<DeleteSlotMutation> {
    const statement = `mutation DeleteSlot($input: DeleteSlotInput!, $condition: ModelSlotConditionInput) {
        deleteSlot(input: $input, condition: $condition) {
          __typename
          id
          start
          date
          type
          interval
          capacity
          filled
          officeId
          office {
            __typename
            id
            country
            state
            city
            address
            parent
            status
            count
            quarantine
            reopening
            ihme_safe
            searchString
            arrival {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            departure {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            break {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            return {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            slots {
              __typename
              nextToken
            }
            users {
              __typename
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSlotMutation>response.data.deleteSlot;
  }
  async CreateNotification(
    input: CreateNotificationInput,
    condition?: ModelNotificationConditionInput
  ): Promise<CreateNotificationMutation> {
    const statement = `mutation CreateNotification($input: CreateNotificationInput!, $condition: ModelNotificationConditionInput) {
        createNotification(input: $input, condition: $condition) {
          __typename
          id
          subject
          message
          from
          replyTo
          officeIds
          time
          slotIds
          userIds
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateNotificationMutation>response.data.createNotification;
  }
  async UpdateNotification(
    input: UpdateNotificationInput,
    condition?: ModelNotificationConditionInput
  ): Promise<UpdateNotificationMutation> {
    const statement = `mutation UpdateNotification($input: UpdateNotificationInput!, $condition: ModelNotificationConditionInput) {
        updateNotification(input: $input, condition: $condition) {
          __typename
          id
          subject
          message
          from
          replyTo
          officeIds
          time
          slotIds
          userIds
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateNotificationMutation>response.data.updateNotification;
  }
  async DeleteNotification(
    input: DeleteNotificationInput,
    condition?: ModelNotificationConditionInput
  ): Promise<DeleteNotificationMutation> {
    const statement = `mutation DeleteNotification($input: DeleteNotificationInput!, $condition: ModelNotificationConditionInput) {
        deleteNotification(input: $input, condition: $condition) {
          __typename
          id
          subject
          message
          from
          replyTo
          officeIds
          time
          slotIds
          userIds
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteNotificationMutation>response.data.deleteNotification;
  }
  async CreateUser(
    input: CreateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<CreateUserMutation> {
    const statement = `mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
        createUser(input: $input, condition: $condition) {
          __typename
          id
          email
          commute
          name
          prefBlock
          prefDays
          prefStart
          quarantine
          wfh
          officeId
          office {
            __typename
            id
            country
            state
            city
            address
            parent
            status
            count
            quarantine
            reopening
            ihme_safe
            searchString
            arrival {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            departure {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            break {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            return {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            slots {
              __typename
              nextToken
            }
            users {
              __typename
              nextToken
            }
            createdAt
            updatedAt
          }
          schedules {
            __typename
            items {
              __typename
              id
              date
              arrivalId
              departureId
              breakId
              returnId
              userId
              createdAt
              updatedAt
            }
            nextToken
          }
          notifications
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserMutation>response.data.createUser;
  }
  async UpdateUser(
    input: UpdateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<UpdateUserMutation> {
    const statement = `mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
        updateUser(input: $input, condition: $condition) {
          __typename
          id
          email
          commute
          name
          prefBlock
          prefDays
          prefStart
          quarantine
          wfh
          officeId
          office {
            __typename
            id
            country
            state
            city
            address
            parent
            status
            count
            quarantine
            reopening
            ihme_safe
            searchString
            arrival {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            departure {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            break {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            return {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            slots {
              __typename
              nextToken
            }
            users {
              __typename
              nextToken
            }
            createdAt
            updatedAt
          }
          schedules {
            __typename
            items {
              __typename
              id
              date
              arrivalId
              departureId
              breakId
              returnId
              userId
              createdAt
              updatedAt
            }
            nextToken
          }
          notifications
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserMutation>response.data.updateUser;
  }
  async DeleteUser(
    input: DeleteUserInput,
    condition?: ModelUserConditionInput
  ): Promise<DeleteUserMutation> {
    const statement = `mutation DeleteUser($input: DeleteUserInput!, $condition: ModelUserConditionInput) {
        deleteUser(input: $input, condition: $condition) {
          __typename
          id
          email
          commute
          name
          prefBlock
          prefDays
          prefStart
          quarantine
          wfh
          officeId
          office {
            __typename
            id
            country
            state
            city
            address
            parent
            status
            count
            quarantine
            reopening
            ihme_safe
            searchString
            arrival {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            departure {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            break {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            return {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            slots {
              __typename
              nextToken
            }
            users {
              __typename
              nextToken
            }
            createdAt
            updatedAt
          }
          schedules {
            __typename
            items {
              __typename
              id
              date
              arrivalId
              departureId
              breakId
              returnId
              userId
              createdAt
              updatedAt
            }
            nextToken
          }
          notifications
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserMutation>response.data.deleteUser;
  }
  async CreateSchedule(
    input: CreateScheduleInput,
    condition?: ModelScheduleConditionInput
  ): Promise<CreateScheduleMutation> {
    const statement = `mutation CreateSchedule($input: CreateScheduleInput!, $condition: ModelScheduleConditionInput) {
        createSchedule(input: $input, condition: $condition) {
          __typename
          id
          date
          arrivalId
          arrival {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          departureId
          departure {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          breakId
          break {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          returnId
          return {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          userId
          user {
            __typename
            id
            email
            commute
            name
            prefBlock
            prefDays
            prefStart
            quarantine
            wfh
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            schedules {
              __typename
              nextToken
            }
            notifications
            createdAt
            updatedAt
          }
          events {
            __typename
            items {
              __typename
              id
              type
              note
              time
              entity
              owner
              scheduleId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateScheduleMutation>response.data.createSchedule;
  }
  async UpdateSchedule(
    input: UpdateScheduleInput,
    condition?: ModelScheduleConditionInput
  ): Promise<UpdateScheduleMutation> {
    const statement = `mutation UpdateSchedule($input: UpdateScheduleInput!, $condition: ModelScheduleConditionInput) {
        updateSchedule(input: $input, condition: $condition) {
          __typename
          id
          date
          arrivalId
          arrival {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          departureId
          departure {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          breakId
          break {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          returnId
          return {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          userId
          user {
            __typename
            id
            email
            commute
            name
            prefBlock
            prefDays
            prefStart
            quarantine
            wfh
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            schedules {
              __typename
              nextToken
            }
            notifications
            createdAt
            updatedAt
          }
          events {
            __typename
            items {
              __typename
              id
              type
              note
              time
              entity
              owner
              scheduleId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateScheduleMutation>response.data.updateSchedule;
  }
  async DeleteSchedule(
    input: DeleteScheduleInput,
    condition?: ModelScheduleConditionInput
  ): Promise<DeleteScheduleMutation> {
    const statement = `mutation DeleteSchedule($input: DeleteScheduleInput!, $condition: ModelScheduleConditionInput) {
        deleteSchedule(input: $input, condition: $condition) {
          __typename
          id
          date
          arrivalId
          arrival {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          departureId
          departure {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          breakId
          break {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          returnId
          return {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          userId
          user {
            __typename
            id
            email
            commute
            name
            prefBlock
            prefDays
            prefStart
            quarantine
            wfh
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            schedules {
              __typename
              nextToken
            }
            notifications
            createdAt
            updatedAt
          }
          events {
            __typename
            items {
              __typename
              id
              type
              note
              time
              entity
              owner
              scheduleId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteScheduleMutation>response.data.deleteSchedule;
  }
  async CreateEvent(
    input: CreateEventInput,
    condition?: ModelEventConditionInput
  ): Promise<CreateEventMutation> {
    const statement = `mutation CreateEvent($input: CreateEventInput!, $condition: ModelEventConditionInput) {
        createEvent(input: $input, condition: $condition) {
          __typename
          id
          type
          note
          time
          entity
          owner
          scheduleId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEventMutation>response.data.createEvent;
  }
  async UpdateEvent(
    input: UpdateEventInput,
    condition?: ModelEventConditionInput
  ): Promise<UpdateEventMutation> {
    const statement = `mutation UpdateEvent($input: UpdateEventInput!, $condition: ModelEventConditionInput) {
        updateEvent(input: $input, condition: $condition) {
          __typename
          id
          type
          note
          time
          entity
          owner
          scheduleId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEventMutation>response.data.updateEvent;
  }
  async DeleteEvent(
    input: DeleteEventInput,
    condition?: ModelEventConditionInput
  ): Promise<DeleteEventMutation> {
    const statement = `mutation DeleteEvent($input: DeleteEventInput!, $condition: ModelEventConditionInput) {
        deleteEvent(input: $input, condition: $condition) {
          __typename
          id
          type
          note
          time
          entity
          owner
          scheduleId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEventMutation>response.data.deleteEvent;
  }
  async CreateVendor(
    input: CreateVendorInput,
    condition?: ModelVendorConditionInput
  ): Promise<CreateVendorMutation> {
    const statement = `mutation CreateVendor($input: CreateVendorInput!, $condition: ModelVendorConditionInput) {
        createVendor(input: $input, condition: $condition) {
          __typename
          id
          type
          status
          options
          name
          visible
          icon
          src
          officeId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateVendorMutation>response.data.createVendor;
  }
  async UpdateVendor(
    input: UpdateVendorInput,
    condition?: ModelVendorConditionInput
  ): Promise<UpdateVendorMutation> {
    const statement = `mutation UpdateVendor($input: UpdateVendorInput!, $condition: ModelVendorConditionInput) {
        updateVendor(input: $input, condition: $condition) {
          __typename
          id
          type
          status
          options
          name
          visible
          icon
          src
          officeId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateVendorMutation>response.data.updateVendor;
  }
  async DeleteVendor(
    input: DeleteVendorInput,
    condition?: ModelVendorConditionInput
  ): Promise<DeleteVendorMutation> {
    const statement = `mutation DeleteVendor($input: DeleteVendorInput!, $condition: ModelVendorConditionInput) {
        deleteVendor(input: $input, condition: $condition) {
          __typename
          id
          type
          status
          options
          name
          visible
          icon
          src
          officeId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteVendorMutation>response.data.deleteVendor;
  }
  async CreateBeacon(
    input: CreateBeaconInput,
    condition?: ModelBeaconConditionInput
  ): Promise<CreateBeaconMutation> {
    const statement = `mutation CreateBeacon($input: CreateBeaconInput!, $condition: ModelBeaconConditionInput) {
        createBeacon(input: $input, condition: $condition) {
          __typename
          id
          uuid
          name
          message
          type
          callback
          officeId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateBeaconMutation>response.data.createBeacon;
  }
  async UpdateBeacon(
    input: UpdateBeaconInput,
    condition?: ModelBeaconConditionInput
  ): Promise<UpdateBeaconMutation> {
    const statement = `mutation UpdateBeacon($input: UpdateBeaconInput!, $condition: ModelBeaconConditionInput) {
        updateBeacon(input: $input, condition: $condition) {
          __typename
          id
          uuid
          name
          message
          type
          callback
          officeId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateBeaconMutation>response.data.updateBeacon;
  }
  async DeleteBeacon(
    input: DeleteBeaconInput,
    condition?: ModelBeaconConditionInput
  ): Promise<DeleteBeaconMutation> {
    const statement = `mutation DeleteBeacon($input: DeleteBeaconInput!, $condition: ModelBeaconConditionInput) {
        deleteBeacon(input: $input, condition: $condition) {
          __typename
          id
          uuid
          name
          message
          type
          callback
          officeId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteBeaconMutation>response.data.deleteBeacon;
  }
  async CreatePublishTest(
    input: CreatePublishTestInput,
    condition?: ModelPublishTestConditionInput
  ): Promise<CreatePublishTestMutation> {
    const statement = `mutation CreatePublishTest($input: CreatePublishTestInput!, $condition: ModelPublishTestConditionInput) {
        createPublishTest(input: $input, condition: $condition) {
          __typename
          id
          name
          description
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePublishTestMutation>response.data.createPublishTest;
  }
  async UpdatePublishTest(
    input: UpdatePublishTestInput,
    condition?: ModelPublishTestConditionInput
  ): Promise<UpdatePublishTestMutation> {
    const statement = `mutation UpdatePublishTest($input: UpdatePublishTestInput!, $condition: ModelPublishTestConditionInput) {
        updatePublishTest(input: $input, condition: $condition) {
          __typename
          id
          name
          description
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePublishTestMutation>response.data.updatePublishTest;
  }
  async DeletePublishTest(
    input: DeletePublishTestInput,
    condition?: ModelPublishTestConditionInput
  ): Promise<DeletePublishTestMutation> {
    const statement = `mutation DeletePublishTest($input: DeletePublishTestInput!, $condition: ModelPublishTestConditionInput) {
        deletePublishTest(input: $input, condition: $condition) {
          __typename
          id
          name
          description
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeletePublishTestMutation>response.data.deletePublishTest;
  }
  async GetOffice(id: string): Promise<GetOfficeQuery> {
    const statement = `query GetOffice($id: ID!) {
        getOffice(id: $id) {
          __typename
          id
          country
          state
          city
          address
          parent
          status
          count
          quarantine
          reopening
          ihme_safe
          searchString
          arrival {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          departure {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          break {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          return {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          slots {
            __typename
            items {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            __typename
            items {
              __typename
              id
              email
              commute
              name
              prefBlock
              prefDays
              prefStart
              quarantine
              wfh
              officeId
              notifications
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOfficeQuery>response.data.getOffice;
  }
  async ListOffices(
    filter?: ModelOfficeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListOfficesQuery> {
    const statement = `query ListOffices($filter: ModelOfficeFilterInput, $limit: Int, $nextToken: String) {
        listOffices(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            country
            state
            city
            address
            parent
            status
            count
            quarantine
            reopening
            ihme_safe
            searchString
            arrival {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            departure {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            break {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            return {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            slots {
              __typename
              nextToken
            }
            users {
              __typename
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOfficesQuery>response.data.listOffices;
  }
  async GetSlot(id: string): Promise<GetSlotQuery> {
    const statement = `query GetSlot($id: ID!) {
        getSlot(id: $id) {
          __typename
          id
          start
          date
          type
          interval
          capacity
          filled
          officeId
          office {
            __typename
            id
            country
            state
            city
            address
            parent
            status
            count
            quarantine
            reopening
            ihme_safe
            searchString
            arrival {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            departure {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            break {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            return {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            slots {
              __typename
              nextToken
            }
            users {
              __typename
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSlotQuery>response.data.getSlot;
  }
  async ListSlots(
    filter?: ModelSlotFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSlotsQuery> {
    const statement = `query ListSlots($filter: ModelSlotFilterInput, $limit: Int, $nextToken: String) {
        listSlots(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSlotsQuery>response.data.listSlots;
  }
  async GetNotification(id: string): Promise<GetNotificationQuery> {
    const statement = `query GetNotification($id: ID!) {
        getNotification(id: $id) {
          __typename
          id
          subject
          message
          from
          replyTo
          officeIds
          time
          slotIds
          userIds
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetNotificationQuery>response.data.getNotification;
  }
  async ListNotifications(
    filter?: ModelNotificationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListNotificationsQuery> {
    const statement = `query ListNotifications($filter: ModelNotificationFilterInput, $limit: Int, $nextToken: String) {
        listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            subject
            message
            from
            replyTo
            officeIds
            time
            slotIds
            userIds
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListNotificationsQuery>response.data.listNotifications;
  }
  async GetUser(id: string): Promise<GetUserQuery> {
    const statement = `query GetUser($id: ID!) {
        getUser(id: $id) {
          __typename
          id
          email
          commute
          name
          prefBlock
          prefDays
          prefStart
          quarantine
          wfh
          officeId
          office {
            __typename
            id
            country
            state
            city
            address
            parent
            status
            count
            quarantine
            reopening
            ihme_safe
            searchString
            arrival {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            departure {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            break {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            return {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            slots {
              __typename
              nextToken
            }
            users {
              __typename
              nextToken
            }
            createdAt
            updatedAt
          }
          schedules {
            __typename
            items {
              __typename
              id
              date
              arrivalId
              departureId
              breakId
              returnId
              userId
              createdAt
              updatedAt
            }
            nextToken
          }
          notifications
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserQuery>response.data.getUser;
  }
  async ListUsers(
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUsersQuery> {
    const statement = `query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            email
            commute
            name
            prefBlock
            prefDays
            prefStart
            quarantine
            wfh
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            schedules {
              __typename
              nextToken
            }
            notifications
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUsersQuery>response.data.listUsers;
  }
  async GetSchedule(id: string): Promise<GetScheduleQuery> {
    const statement = `query GetSchedule($id: ID!) {
        getSchedule(id: $id) {
          __typename
          id
          date
          arrivalId
          arrival {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          departureId
          departure {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          breakId
          break {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          returnId
          return {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          userId
          user {
            __typename
            id
            email
            commute
            name
            prefBlock
            prefDays
            prefStart
            quarantine
            wfh
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            schedules {
              __typename
              nextToken
            }
            notifications
            createdAt
            updatedAt
          }
          events {
            __typename
            items {
              __typename
              id
              type
              note
              time
              entity
              owner
              scheduleId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetScheduleQuery>response.data.getSchedule;
  }
  async ListSchedules(
    filter?: ModelScheduleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSchedulesQuery> {
    const statement = `query ListSchedules($filter: ModelScheduleFilterInput, $limit: Int, $nextToken: String) {
        listSchedules(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            date
            arrivalId
            arrival {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            departureId
            departure {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            breakId
            break {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            returnId
            return {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            userId
            user {
              __typename
              id
              email
              commute
              name
              prefBlock
              prefDays
              prefStart
              quarantine
              wfh
              officeId
              notifications
              createdAt
              updatedAt
            }
            events {
              __typename
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSchedulesQuery>response.data.listSchedules;
  }
  async GetEvent(id: string): Promise<GetEventQuery> {
    const statement = `query GetEvent($id: ID!) {
        getEvent(id: $id) {
          __typename
          id
          type
          note
          time
          entity
          owner
          scheduleId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEventQuery>response.data.getEvent;
  }
  async ListEvents(
    filter?: ModelEventFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEventsQuery> {
    const statement = `query ListEvents($filter: ModelEventFilterInput, $limit: Int, $nextToken: String) {
        listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            type
            note
            time
            entity
            owner
            scheduleId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEventsQuery>response.data.listEvents;
  }
  async GetVendor(id: string): Promise<GetVendorQuery> {
    const statement = `query GetVendor($id: ID!) {
        getVendor(id: $id) {
          __typename
          id
          type
          status
          options
          name
          visible
          icon
          src
          officeId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetVendorQuery>response.data.getVendor;
  }
  async ListVendors(
    filter?: ModelVendorFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListVendorsQuery> {
    const statement = `query ListVendors($filter: ModelVendorFilterInput, $limit: Int, $nextToken: String) {
        listVendors(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            type
            status
            options
            name
            visible
            icon
            src
            officeId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVendorsQuery>response.data.listVendors;
  }
  async GetBeacon(id: string): Promise<GetBeaconQuery> {
    const statement = `query GetBeacon($id: ID!) {
        getBeacon(id: $id) {
          __typename
          id
          uuid
          name
          message
          type
          callback
          officeId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetBeaconQuery>response.data.getBeacon;
  }
  async ListBeacons(
    filter?: ModelBeaconFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListBeaconsQuery> {
    const statement = `query ListBeacons($filter: ModelBeaconFilterInput, $limit: Int, $nextToken: String) {
        listBeacons(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            uuid
            name
            message
            type
            callback
            officeId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListBeaconsQuery>response.data.listBeacons;
  }
  async GetPublishTest(id: string): Promise<GetPublishTestQuery> {
    const statement = `query GetPublishTest($id: ID!) {
        getPublishTest(id: $id) {
          __typename
          id
          name
          description
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPublishTestQuery>response.data.getPublishTest;
  }
  async ListPublishTests(
    filter?: ModelPublishTestFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListPublishTestsQuery> {
    const statement = `query ListPublishTests($filter: ModelPublishTestFilterInput, $limit: Int, $nextToken: String) {
        listPublishTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            description
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPublishTestsQuery>response.data.listPublishTests;
  }
  async ListSlotsByOfficeByStart(
    officeId?: string,
    start?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelSlotFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSlotsByOfficeByStartQuery> {
    const statement = `query ListSlotsByOfficeByStart($officeId: ID, $start: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelSlotFilterInput, $limit: Int, $nextToken: String) {
        listSlotsByOfficeByStart(officeId: $officeId, start: $start, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (officeId) {
      gqlAPIServiceArguments.officeId = officeId;
    }
    if (start) {
      gqlAPIServiceArguments.start = start;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSlotsByOfficeByStartQuery>(
      response.data.listSlotsByOfficeByStart
    );
  }
  async ListSchedulesByUserByDate(
    userId?: string,
    date?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelScheduleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSchedulesByUserByDateQuery> {
    const statement = `query ListSchedulesByUserByDate($userId: ID, $date: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelScheduleFilterInput, $limit: Int, $nextToken: String) {
        listSchedulesByUserByDate(userId: $userId, date: $date, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            date
            arrivalId
            arrival {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            departureId
            departure {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            breakId
            break {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            returnId
            return {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            userId
            user {
              __typename
              id
              email
              commute
              name
              prefBlock
              prefDays
              prefStart
              quarantine
              wfh
              officeId
              notifications
              createdAt
              updatedAt
            }
            events {
              __typename
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (date) {
      gqlAPIServiceArguments.date = date;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSchedulesByUserByDateQuery>(
      response.data.listSchedulesByUserByDate
    );
  }
  async ListSchedulesByDate(
    date?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelScheduleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSchedulesByDateQuery> {
    const statement = `query ListSchedulesByDate($date: AWSDate, $sortDirection: ModelSortDirection, $filter: ModelScheduleFilterInput, $limit: Int, $nextToken: String) {
        listSchedulesByDate(date: $date, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            date
            arrivalId
            arrival {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            departureId
            departure {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            breakId
            break {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            returnId
            return {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            userId
            user {
              __typename
              id
              email
              commute
              name
              prefBlock
              prefDays
              prefStart
              quarantine
              wfh
              officeId
              notifications
              createdAt
              updatedAt
            }
            events {
              __typename
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (date) {
      gqlAPIServiceArguments.date = date;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSchedulesByDateQuery>response.data.listSchedulesByDate;
  }
  async ListSchedulesByArrivalId(
    arrivalId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelScheduleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSchedulesByArrivalIdQuery> {
    const statement = `query ListSchedulesByArrivalId($arrivalId: ID, $sortDirection: ModelSortDirection, $filter: ModelScheduleFilterInput, $limit: Int, $nextToken: String) {
        listSchedulesByArrivalId(arrivalId: $arrivalId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            date
            arrivalId
            arrival {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            departureId
            departure {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            breakId
            break {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            returnId
            return {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            userId
            user {
              __typename
              id
              email
              commute
              name
              prefBlock
              prefDays
              prefStart
              quarantine
              wfh
              officeId
              notifications
              createdAt
              updatedAt
            }
            events {
              __typename
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (arrivalId) {
      gqlAPIServiceArguments.arrivalId = arrivalId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSchedulesByArrivalIdQuery>(
      response.data.listSchedulesByArrivalId
    );
  }
  async ListSchedulesByDepartureId(
    departureId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelScheduleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSchedulesByDepartureIdQuery> {
    const statement = `query ListSchedulesByDepartureId($departureId: ID, $sortDirection: ModelSortDirection, $filter: ModelScheduleFilterInput, $limit: Int, $nextToken: String) {
        listSchedulesByDepartureId(departureId: $departureId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            date
            arrivalId
            arrival {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            departureId
            departure {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            breakId
            break {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            returnId
            return {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            userId
            user {
              __typename
              id
              email
              commute
              name
              prefBlock
              prefDays
              prefStart
              quarantine
              wfh
              officeId
              notifications
              createdAt
              updatedAt
            }
            events {
              __typename
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (departureId) {
      gqlAPIServiceArguments.departureId = departureId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSchedulesByDepartureIdQuery>(
      response.data.listSchedulesByDepartureId
    );
  }
  async ListSchedulesByBreakId(
    breakId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelScheduleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSchedulesByBreakIdQuery> {
    const statement = `query ListSchedulesByBreakId($breakId: ID, $sortDirection: ModelSortDirection, $filter: ModelScheduleFilterInput, $limit: Int, $nextToken: String) {
        listSchedulesByBreakId(breakId: $breakId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            date
            arrivalId
            arrival {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            departureId
            departure {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            breakId
            break {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            returnId
            return {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            userId
            user {
              __typename
              id
              email
              commute
              name
              prefBlock
              prefDays
              prefStart
              quarantine
              wfh
              officeId
              notifications
              createdAt
              updatedAt
            }
            events {
              __typename
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (breakId) {
      gqlAPIServiceArguments.breakId = breakId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSchedulesByBreakIdQuery>response.data.listSchedulesByBreakId;
  }
  async ListSchedulesByReturnId(
    returnId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelScheduleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSchedulesByReturnIdQuery> {
    const statement = `query ListSchedulesByReturnId($returnId: ID, $sortDirection: ModelSortDirection, $filter: ModelScheduleFilterInput, $limit: Int, $nextToken: String) {
        listSchedulesByReturnId(returnId: $returnId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            date
            arrivalId
            arrival {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            departureId
            departure {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            breakId
            break {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            returnId
            return {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            userId
            user {
              __typename
              id
              email
              commute
              name
              prefBlock
              prefDays
              prefStart
              quarantine
              wfh
              officeId
              notifications
              createdAt
              updatedAt
            }
            events {
              __typename
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (returnId) {
      gqlAPIServiceArguments.returnId = returnId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSchedulesByReturnIdQuery>response.data.listSchedulesByReturnId;
  }
  async ListVendorsByOffice(
    officeId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelVendorFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListVendorsByOfficeQuery> {
    const statement = `query ListVendorsByOffice($officeId: String, $sortDirection: ModelSortDirection, $filter: ModelVendorFilterInput, $limit: Int, $nextToken: String) {
        listVendorsByOffice(officeId: $officeId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            type
            status
            options
            name
            visible
            icon
            src
            officeId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (officeId) {
      gqlAPIServiceArguments.officeId = officeId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVendorsByOfficeQuery>response.data.listVendorsByOffice;
  }
  async ListBeaconsByOffice(
    officeId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelBeaconFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListBeaconsByOfficeQuery> {
    const statement = `query ListBeaconsByOffice($officeId: String, $sortDirection: ModelSortDirection, $filter: ModelBeaconFilterInput, $limit: Int, $nextToken: String) {
        listBeaconsByOffice(officeId: $officeId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            uuid
            name
            message
            type
            callback
            officeId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (officeId) {
      gqlAPIServiceArguments.officeId = officeId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListBeaconsByOfficeQuery>response.data.listBeaconsByOffice;
  }
  async SearchUsers(
    filter?: SearchableUserFilterInput,
    sort?: SearchableUserSortInput,
    limit?: number,
    nextToken?: string,
    from?: number
  ): Promise<SearchUsersQuery> {
    const statement = `query SearchUsers($filter: SearchableUserFilterInput, $sort: SearchableUserSortInput, $limit: Int, $nextToken: String, $from: Int) {
        searchUsers(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken, from: $from) {
          __typename
          items {
            __typename
            id
            email
            commute
            name
            prefBlock
            prefDays
            prefStart
            quarantine
            wfh
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            schedules {
              __typename
              nextToken
            }
            notifications
            createdAt
            updatedAt
          }
          nextToken
          total
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (sort) {
      gqlAPIServiceArguments.sort = sort;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (from) {
      gqlAPIServiceArguments.from = from;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SearchUsersQuery>response.data.searchUsers;
  }
  OnCreateOfficeListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateOffice">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateOffice {
        onCreateOffice {
          __typename
          id
          country
          state
          city
          address
          parent
          status
          count
          quarantine
          reopening
          ihme_safe
          searchString
          arrival {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          departure {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          break {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          return {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          slots {
            __typename
            items {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            __typename
            items {
              __typename
              id
              email
              commute
              name
              prefBlock
              prefDays
              prefStart
              quarantine
              wfh
              officeId
              notifications
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateOffice">>
  >;

  OnUpdateOfficeListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateOffice">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateOffice {
        onUpdateOffice {
          __typename
          id
          country
          state
          city
          address
          parent
          status
          count
          quarantine
          reopening
          ihme_safe
          searchString
          arrival {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          departure {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          break {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          return {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          slots {
            __typename
            items {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            __typename
            items {
              __typename
              id
              email
              commute
              name
              prefBlock
              prefDays
              prefStart
              quarantine
              wfh
              officeId
              notifications
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateOffice">>
  >;

  OnDeleteOfficeListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteOffice">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteOffice {
        onDeleteOffice {
          __typename
          id
          country
          state
          city
          address
          parent
          status
          count
          quarantine
          reopening
          ihme_safe
          searchString
          arrival {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          departure {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          break {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          return {
            __typename
            enabled
            capacity
            start
            end
            type
            blocks
            interval
          }
          slots {
            __typename
            items {
              __typename
              id
              start
              date
              type
              interval
              capacity
              filled
              officeId
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            __typename
            items {
              __typename
              id
              email
              commute
              name
              prefBlock
              prefDays
              prefStart
              quarantine
              wfh
              officeId
              notifications
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteOffice">>
  >;

  OnCreateSlotListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSlot">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateSlot {
        onCreateSlot {
          __typename
          id
          start
          date
          type
          interval
          capacity
          filled
          officeId
          office {
            __typename
            id
            country
            state
            city
            address
            parent
            status
            count
            quarantine
            reopening
            ihme_safe
            searchString
            arrival {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            departure {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            break {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            return {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            slots {
              __typename
              nextToken
            }
            users {
              __typename
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSlot">>
  >;

  OnUpdateSlotListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSlot">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateSlot {
        onUpdateSlot {
          __typename
          id
          start
          date
          type
          interval
          capacity
          filled
          officeId
          office {
            __typename
            id
            country
            state
            city
            address
            parent
            status
            count
            quarantine
            reopening
            ihme_safe
            searchString
            arrival {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            departure {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            break {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            return {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            slots {
              __typename
              nextToken
            }
            users {
              __typename
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSlot">>
  >;

  OnDeleteSlotListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSlot">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteSlot {
        onDeleteSlot {
          __typename
          id
          start
          date
          type
          interval
          capacity
          filled
          officeId
          office {
            __typename
            id
            country
            state
            city
            address
            parent
            status
            count
            quarantine
            reopening
            ihme_safe
            searchString
            arrival {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            departure {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            break {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            return {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            slots {
              __typename
              nextToken
            }
            users {
              __typename
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSlot">>
  >;

  OnCreateNotificationListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateNotification">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateNotification {
        onCreateNotification {
          __typename
          id
          subject
          message
          from
          replyTo
          officeIds
          time
          slotIds
          userIds
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateNotification">>
  >;

  OnUpdateNotificationListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateNotification">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateNotification {
        onUpdateNotification {
          __typename
          id
          subject
          message
          from
          replyTo
          officeIds
          time
          slotIds
          userIds
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateNotification">>
  >;

  OnDeleteNotificationListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteNotification">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteNotification {
        onDeleteNotification {
          __typename
          id
          subject
          message
          from
          replyTo
          officeIds
          time
          slotIds
          userIds
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteNotification">>
  >;

  OnCreateUserListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUser">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateUser {
        onCreateUser {
          __typename
          id
          email
          commute
          name
          prefBlock
          prefDays
          prefStart
          quarantine
          wfh
          officeId
          office {
            __typename
            id
            country
            state
            city
            address
            parent
            status
            count
            quarantine
            reopening
            ihme_safe
            searchString
            arrival {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            departure {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            break {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            return {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            slots {
              __typename
              nextToken
            }
            users {
              __typename
              nextToken
            }
            createdAt
            updatedAt
          }
          schedules {
            __typename
            items {
              __typename
              id
              date
              arrivalId
              departureId
              breakId
              returnId
              userId
              createdAt
              updatedAt
            }
            nextToken
          }
          notifications
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUser">>
  >;

  OnUpdateUserListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUser">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateUser {
        onUpdateUser {
          __typename
          id
          email
          commute
          name
          prefBlock
          prefDays
          prefStart
          quarantine
          wfh
          officeId
          office {
            __typename
            id
            country
            state
            city
            address
            parent
            status
            count
            quarantine
            reopening
            ihme_safe
            searchString
            arrival {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            departure {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            break {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            return {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            slots {
              __typename
              nextToken
            }
            users {
              __typename
              nextToken
            }
            createdAt
            updatedAt
          }
          schedules {
            __typename
            items {
              __typename
              id
              date
              arrivalId
              departureId
              breakId
              returnId
              userId
              createdAt
              updatedAt
            }
            nextToken
          }
          notifications
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUser">>
  >;

  OnDeleteUserListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUser">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteUser {
        onDeleteUser {
          __typename
          id
          email
          commute
          name
          prefBlock
          prefDays
          prefStart
          quarantine
          wfh
          officeId
          office {
            __typename
            id
            country
            state
            city
            address
            parent
            status
            count
            quarantine
            reopening
            ihme_safe
            searchString
            arrival {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            departure {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            break {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            return {
              __typename
              enabled
              capacity
              start
              end
              type
              blocks
              interval
            }
            slots {
              __typename
              nextToken
            }
            users {
              __typename
              nextToken
            }
            createdAt
            updatedAt
          }
          schedules {
            __typename
            items {
              __typename
              id
              date
              arrivalId
              departureId
              breakId
              returnId
              userId
              createdAt
              updatedAt
            }
            nextToken
          }
          notifications
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUser">>
  >;

  OnCreateScheduleListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSchedule">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateSchedule {
        onCreateSchedule {
          __typename
          id
          date
          arrivalId
          arrival {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          departureId
          departure {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          breakId
          break {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          returnId
          return {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          userId
          user {
            __typename
            id
            email
            commute
            name
            prefBlock
            prefDays
            prefStart
            quarantine
            wfh
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            schedules {
              __typename
              nextToken
            }
            notifications
            createdAt
            updatedAt
          }
          events {
            __typename
            items {
              __typename
              id
              type
              note
              time
              entity
              owner
              scheduleId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSchedule">>
  >;

  OnUpdateScheduleListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSchedule">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateSchedule {
        onUpdateSchedule {
          __typename
          id
          date
          arrivalId
          arrival {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          departureId
          departure {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          breakId
          break {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          returnId
          return {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          userId
          user {
            __typename
            id
            email
            commute
            name
            prefBlock
            prefDays
            prefStart
            quarantine
            wfh
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            schedules {
              __typename
              nextToken
            }
            notifications
            createdAt
            updatedAt
          }
          events {
            __typename
            items {
              __typename
              id
              type
              note
              time
              entity
              owner
              scheduleId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSchedule">>
  >;

  OnDeleteScheduleListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSchedule">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteSchedule {
        onDeleteSchedule {
          __typename
          id
          date
          arrivalId
          arrival {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          departureId
          departure {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          breakId
          break {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          returnId
          return {
            __typename
            id
            start
            date
            type
            interval
            capacity
            filled
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          userId
          user {
            __typename
            id
            email
            commute
            name
            prefBlock
            prefDays
            prefStart
            quarantine
            wfh
            officeId
            office {
              __typename
              id
              country
              state
              city
              address
              parent
              status
              count
              quarantine
              reopening
              ihme_safe
              searchString
              createdAt
              updatedAt
            }
            schedules {
              __typename
              nextToken
            }
            notifications
            createdAt
            updatedAt
          }
          events {
            __typename
            items {
              __typename
              id
              type
              note
              time
              entity
              owner
              scheduleId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSchedule">>
  >;

  OnCreateEventListener(
    owner?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateEvent">>
  > {
    const statement = `subscription OnCreateEvent($owner: String) {
        onCreateEvent(owner: $owner) {
          __typename
          id
          type
          note
          time
          entity
          owner
          scheduleId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateEvent">>
    >;
  }

  OnUpdateEventListener(
    owner?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateEvent">>
  > {
    const statement = `subscription OnUpdateEvent($owner: String) {
        onUpdateEvent(owner: $owner) {
          __typename
          id
          type
          note
          time
          entity
          owner
          scheduleId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateEvent">>
    >;
  }

  OnDeleteEventListener(
    owner?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteEvent">>
  > {
    const statement = `subscription OnDeleteEvent($owner: String) {
        onDeleteEvent(owner: $owner) {
          __typename
          id
          type
          note
          time
          entity
          owner
          scheduleId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteEvent">>
    >;
  }

  OnCreateVendorListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateVendor">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateVendor {
        onCreateVendor {
          __typename
          id
          type
          status
          options
          name
          visible
          icon
          src
          officeId
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateVendor">>
  >;

  OnUpdateVendorListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateVendor">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateVendor {
        onUpdateVendor {
          __typename
          id
          type
          status
          options
          name
          visible
          icon
          src
          officeId
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateVendor">>
  >;

  OnDeleteVendorListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteVendor">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteVendor {
        onDeleteVendor {
          __typename
          id
          type
          status
          options
          name
          visible
          icon
          src
          officeId
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteVendor">>
  >;

  OnCreateBeaconListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateBeacon">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateBeacon {
        onCreateBeacon {
          __typename
          id
          uuid
          name
          message
          type
          callback
          officeId
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateBeacon">>
  >;

  OnUpdateBeaconListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateBeacon">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateBeacon {
        onUpdateBeacon {
          __typename
          id
          uuid
          name
          message
          type
          callback
          officeId
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateBeacon">>
  >;

  OnDeleteBeaconListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteBeacon">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteBeacon {
        onDeleteBeacon {
          __typename
          id
          uuid
          name
          message
          type
          callback
          officeId
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteBeacon">>
  >;

  OnCreatePublishTestListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreatePublishTest">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreatePublishTest {
        onCreatePublishTest {
          __typename
          id
          name
          description
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreatePublishTest">>
  >;

  OnUpdatePublishTestListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdatePublishTest">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdatePublishTest {
        onUpdatePublishTest {
          __typename
          id
          name
          description
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdatePublishTest">>
  >;

  OnDeletePublishTestListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeletePublishTest">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeletePublishTest {
        onDeletePublishTest {
          __typename
          id
          name
          description
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeletePublishTest">>
  >;
}
