import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { TimeAgoPipe } from "./time-ago.pipe";

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [TimeAgoPipe],
  exports: [TimeAgoPipe],
})
export class PipesModule {}
