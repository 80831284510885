// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  local: false,
  mobile: false,
  aws: {
    aws_project_region: "us-east-1",
    aws_cognito_identity_pool_id:
      "us-east-1:81800eae-5c9b-430d-8c5b-9193f81d093a",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_h75xJnL0s",
    aws_user_pools_web_client_id: "5gpnup7b7tp55v058v0pkfqibv",
    aws_cloud_logic_custom: [
      {
        name: "AdminQueries",
        endpoint:
          "https://lf3ijxqv2e.execute-api.us-east-1.amazonaws.com/nonprod",
        region: "us-east-1",
      },
    ],
    aws_appsync_graphqlEndpoint:
      "https://3ug3u5ex6za55lx2ghimts77cq.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    oauth: {
      dlRedirectSignIn:
        "veriskscheduler://checkin.verisk.com/auth/callback/signin/",
      dlRedirectSignOut:
        "veriskscheduler://checkin.verisk.com/auth/callback/signout/",
      domain: "rto-app-nonprod.auth.us-east-1.amazoncognito.com",
      scope: [
        "openid",
        "profile",
        "email",
        "phone",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn:
        "https://checkin.verisk.com/auth/callback/signin/",
      redirectSignOut:
        "https://checkin.verisk.com/auth/callback/signout/",
      responseType: "code",
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
