import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PageLoadingComponent } from './page-loading/page-loading.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { FormsModule } from '@angular/forms';
import { SearchModalComponent } from './search-modal/search-modal.component';
import { WeekTabComponent } from './week-tab/week-tab.component';
import { QRScannerComponent } from './qrscanner/qrscanner.component';
import { DynamicPopoverComponent } from './dynamic-popover/dynamic-popover.component';
import { LogoComponent } from './logo/logo.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
  declarations: [
    PageLoadingComponent,
    EmptyStateComponent,
    SearchModalComponent,
    WeekTabComponent,
    QRScannerComponent,
    DynamicPopoverComponent,
    LogoComponent,
    MaintenanceComponent,
  ],
  exports: [
    PageLoadingComponent,
    EmptyStateComponent,
    SearchModalComponent,
    WeekTabComponent,
    QRScannerComponent,
    DynamicPopoverComponent,
    LogoComponent,
    MaintenanceComponent,
  ],
  entryComponents: [
    PageLoadingComponent,
  ]
})
export class ComponentsModule { }
